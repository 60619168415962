//   kr 6
import React, { useState, useEffect } from "react";
import styles from "./ServiceForm.module.css";
import YearDropdown from "../../../UI/Dropdown/YearDropDown";
import Dropdown from "../../../UI/Dropdown/Dropdown";
import arrowDown from "/assets/common/ArrowDown_Filled.svg";
import QuotePopup from "../ServiceManagement/QuotePopup";
import { MONTH_NAMES_SHORT } from "../../../../consts/Constants";
import { useDispatch } from "react-redux";
import { serviceActions } from "../reducers/service-slice";
import ButtonCustom from "../../../UI/Button/ButtonCustom";
import { useSelector } from "react-redux";
import { useGetTRCYearsQuery } from "../apis/serviceApi-slice";
import LoadingComponent from "../../../UI/LoadingIndicator/LoadingComponent";
import ErrorComponent from "../../../UI/Error/ErrorComponent";

const ServiceForm = ({
  selectedService: serviceToDisplay,
  onQuotePopupOpen,
  handleAddButtonClick,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [description, setDescription] = useState("");
  const [auditFromYear, setAuditFromYear] = useState("");
  const [auditFromMonth, setAuditFromMonth] = useState("");
  const [auditToYear, setAuditToYear] = useState("");
  const [auditToMonth, setAuditToMonth] = useState("");
  const [isAddButtonEnabled, setIsAddButtonEnabled] = useState(false);
  const [isGetQuoteButtonEnabled, setIsGetQuoteButtonEnabled] = useState(false);
  const [isQuotePopupOpen, setIsQuotePopupOpen] = useState(false);

  const { primaryEntity } = useSelector((state) => state.entity);
  const currentYear = new Date().getFullYear();
  const incorporationYear = primaryEntity
    ? new Date(primaryEntity.incorporationDate).getFullYear()
    : 2022;

  const {
    data: trcYears,
    isFetching: isTrcYearsLoading,
    isError: isTrcYearsError,
  } = useGetTRCYearsQuery(
    { entityId: primaryEntity?.entityId },
    { refetchOnMountOrArgChange: true }
  );

  const getServiceConfigs = (serviceName) => {
    switch (serviceName) {
      case "Tax Residency Certificate":
        return { selectedYear };
      case "Audit & Review Report":
        return {
          auditFromYear,
          auditFromMonth,
          auditToYear,
          auditToMonth,
        };
      case "Other":
        return { serviceName, description };
      default:
        return {};
    }
  };
  const isAuditPeriodValid = () => {
    if (!auditFromYear || !auditFromMonth || !auditToYear || !auditToMonth) {
      return false;
    }

    // Convert months to numbers for comparison
    const fromMonthIndex = MONTH_NAMES_SHORT.indexOf(auditFromMonth);
    const toMonthIndex = MONTH_NAMES_SHORT.indexOf(auditToMonth);

    if (parseInt(auditFromYear) > parseInt(auditToYear)) {
      return false; // 'From Year' cannot be greater than 'To Year'
    }

    if (parseInt(auditFromYear) === parseInt(auditToYear)) {
      // Same year: 'From Month' should be less than 'To Month'
      if (fromMonthIndex >= toMonthIndex) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (serviceToDisplay?.serviceName === "Tax Residency Certificate") {
      setIsAddButtonEnabled(selectedYear);
    } else if (serviceToDisplay?.serviceName === "Audit & Review Report") {
      setIsAddButtonEnabled(
        auditFromYear &&
          auditFromMonth &&
          auditToYear &&
          auditToMonth &&
          isAuditPeriodValid()
      );
    } else if (serviceToDisplay?.serviceName === "Other") {
      setIsGetQuoteButtonEnabled(serviceName && description);
    } else {
      setIsAddButtonEnabled(false);
    }
  }, [
    selectedYear,
    auditFromYear,
    auditFromMonth,
    auditToYear,
    auditToMonth,
    serviceName,
    description,
    serviceToDisplay,
  ]);

  const handleAddButtonClicked = () => {
    const data = getServiceConfigs(serviceToDisplay.serviceName);
    const serviceData = {
      serviceId: serviceToDisplay.serviceId,
      serviceName: serviceToDisplay.serviceName,
      servicePrice: serviceToDisplay.servicePrice,
      serviceDescription:
        serviceToDisplay.serviceName === "Other"
          ? description
          : serviceToDisplay.serviceDescription,
      data,
    };
    handleAddButtonClick(serviceData);
  };

  const handleGetQuoteClick = () => {
    setIsQuotePopupOpen(true);

    if (isAddButtonEnabled) {
      setIsQuotePopupOpen(true);
      onQuotePopupOpen();
    }
  };
  const handleClosePopup = () => {
    //here i need to send post request for custome service
    setIsQuotePopupOpen(false);
    onClose();

    const serviceData = {
      serviceId: serviceToDisplay.serviceId,
      serviceName: serviceName,
      serviceDescription: description,
    };
    dispatch(serviceActions.addService(serviceData));
  };
  if (!serviceToDisplay) return null;
  return (
    <div className={styles.right_container}>
      <div className={styles.right_cont}>
        <div className={styles.right_cont_header}>
          <p className={styles.para}>{serviceToDisplay.serviceDescription}</p>
          <div className={styles.price}>${serviceToDisplay.servicePrice}</div>
        </div>
        <div className={styles.right_cont_middle}>
          {serviceToDisplay.serviceName === "Tax Residency Certificate" && (
            <>
              {isTrcYearsLoading && <LoadingComponent />}
              {isTrcYearsError && <ErrorComponent errorMessage="Something went wrong. Please try again" />}
              {(trcYears && trcYears?.years?.length > 0) && (
                <div className={styles.first_pop_middle_cont}>
                  <label
                    htmlFor="yearSelect"
                    className={styles.first_pop_label}
                  >
                    Year
                  </label>
                  <YearDropdown
                    placeholder=""
                    // start={incorporationYear}
                    // end={currentYear + 1}
                    month={""}
                    showMonth={false}
                    customOptions={trcYears?.years || []}
                    selected={selectedYear}
                    onSelect={setSelectedYear}
                    id="yearSelect"
                    dropdownSize={styles.year_dropdown}
                    className={styles.dropdown}
                    arrow={arrowDown}
                    listStyle={styles.dropDownList}
                    listItemStyle={styles.list_item}
                    headerStyle={styles.dropdownHeader}
                  />
                </div>
              )}
              {(trcYears && trcYears?.years?.length === 0) && (
                <ErrorComponent errorMessage="No years found" />
              )}
            </>
          )}
          {serviceToDisplay.serviceName === "Audit & Review Report" && (
            <div className={styles.Acc_main_cont}>
              <div className={styles.first_pop_middle_cont_years_cont}>
                <div className={styles.from_cont}>
                  <div className={styles.from}>
                    <label
                      htmlFor="fromYearSelect"
                      className={styles.first_pop_label}
                    >
                      FROM
                    </label>
                    <YearDropdown
                      placeholder="Year"
                      start={incorporationYear}
                      end={currentYear}
                      month={""}
                      showMonth={false}
                      selected={auditFromYear}
                      onSelect={setAuditFromYear}
                      id="fromYearSelect"
                      dropdownSize={styles.year_dropdown}
                      className={styles.dropdown}
                      arrow={arrowDown}
                      listStyle={styles.dropDownList}
                      listItemStyle={styles.list_item}
                      headerStyle={styles.dropdownHeader}
                    />
                  </div>
                  <div className={styles.month_cont}>
                    <Dropdown
                      className={styles.dropdown}
                      placeholder="Month"
                      options={MONTH_NAMES_SHORT}
                      selected={auditFromMonth}
                      onSelect={setAuditFromMonth}
                      id="fromMonthSelect"
                      dropdownSize={styles.year_dropdown}
                      dropdownListSize={styles.dropdown_list}
                      arrow={arrowDown}
                    />
                  </div>
                </div>

                <div className={styles.from_cont}>
                  <div className={styles.from}>
                    <label
                      htmlFor="toYearSelect"
                      className={styles.first_pop_label}
                    >
                      TO
                    </label>
                    <YearDropdown
                      placeholder="Year"
                      start={incorporationYear}
                      end={currentYear}
                      month={""}
                      showMonth={false}
                      selected={auditToYear}
                      onSelect={setAuditToYear}
                      id="toYearSelect"
                      dropdownSize={styles.year_dropdown}
                      className={styles.dropdown}
                      arrow={arrowDown}
                      listStyle={styles.dropDownList}
                      listItemStyle={styles.list_item}
                      headerStyle={styles.dropdownHeader}
                    />
                  </div>
                  <div className={styles.month_cont}>
                    <Dropdown
                      className={styles.dropdown}
                      placeholder="Month"
                      options={MONTH_NAMES_SHORT}
                      selected={auditToMonth}
                      onSelect={setAuditToMonth}
                      id="toMonthSelect"
                      dropdownSize={styles.year_dropdown}
                      dropdownListSize={styles.dropdown_list}
                      arrow={arrowDown}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {serviceToDisplay.serviceName === "Other" && (
            <div className={styles.o}>
              <div className={styles.other_ser_name}>
                <h3 className={styles.other_service_name}>Service name</h3>
                <div className={styles.other_service_input}>
                  <input
                    type="text"
                    value={serviceName}
                    placeholder="Enter service name..."
                    onChange={(e) => setServiceName(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.text_area_container}>
                <textarea
                  className={styles.other_service_textbox}
                  placeholder="Enter description..."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div className={styles.right_cont_footer}>
          {serviceToDisplay.serviceName === "Other" && (
            <ButtonCustom
              className={styles.ui__button}
              onClick={handleGetQuoteClick}
              disabled={!isGetQuoteButtonEnabled}
            >
              Get Quote
            </ButtonCustom>
          )}
          {(serviceToDisplay.serviceName === "Tax Residency Certificate" ||
            serviceToDisplay.serviceName === "Audit & Review Report") && (
            <ButtonCustom
              className={styles.ui__button}
              onClick={handleAddButtonClicked}
              disabled={!isAddButtonEnabled}
            >
              {" "}
              ADD
            </ButtonCustom>
          )}
        </div>
      </div>
      {isQuotePopupOpen && <QuotePopup onClose={handleClosePopup} />}
    </div>
  );
};

export default ServiceForm;
