import { LBL__NO, LBL__YES } from "../../../consts/TextConstants";
import ButtonCustom from "../../UI/Button/ButtonCustom";
import OverlayRegular from "../../UI/Modal/OverlayRegular";
import styles from "./AccountingPermissionOverlay.module.css";
import closeIcon from "/assets/Close.svg";
import addIcon from "/assets/common/Plus_White.svg";

const AccountingPermissionOverlay = ({ className, ...props }) => {
  return (
    <OverlayRegular
      keyName="edit-overlay"
      className={`${styles.overlay} ${className}`}
      blurValue="0.15rem"
    >
      <button
        className={styles.btn__close}
        onClick={() => props.onClose(false)}
      >
        <img src={closeIcon} alt="" />
      </button>
      <div className={styles.title}>Accounting</div>
      <div className={styles.txt__permission}>
        Do you want Merze to prepare Financials?
      </div>
      <div className={styles.action__items}>
        <ButtonCustom
          className={styles.btn__no}
          onClick={() => props.onClose(false)}
        >
          {LBL__NO}
        </ButtonCustom>
        <ButtonCustom onClick={() => props.onClose(true)}>{LBL__YES}</ButtonCustom>
      </div>
    </OverlayRegular>
  );
};

export default AccountingPermissionOverlay;
