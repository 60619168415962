
import React from 'react';
import OverlayRegular from "../../../UI/Modal/OverlayRegular";
import styles from './ServicesAdded.module.css';
import closeIcon from '/assets/Close.svg';
import { useAddServicesMutation, useLazyGetAllUserServicesQuery } from '../apis/serviceApi-slice';
import arrowWhite from '/assets/common/ArrowRight_White.svg';
import { useSelector } from 'react-redux';
import { DEFAULT_PAGE_SIZE } from '../../../../consts/AppConstants';
const ServicesAdded = ({ selectedService = {}, onClose, onSuccess }) => {

    const { primaryEntity } = useSelector(state => state.entity);
    const [addService] = useAddServicesMutation();
    const [getAllServices] = useLazyGetAllUserServicesQuery();

    const handlePayClick = async () => {
        if (!selectedService || !selectedService.serviceId) return;

        try {
            const serviceInput = selectedService.serviceName === "Tax Residency Certificate"
                ? { year: selectedService?.data?.selectedYear || 'N/A' }
                : selectedService.serviceName === "Audit & Review Report"
                    ? {
                        auditFromYear: selectedService?.data?.auditFromYear || 'N/A',
                        auditFromMonth: selectedService?.data?.auditFromMonth || 'N/A',
                        auditToYear: selectedService?.data?.auditToYear || 'N/A',
                        auditToMonth: selectedService?.data?.auditToMonth || 'N/A',
                    }
                    : { year: 'N/A' };

            const postData = {

                service_input: serviceInput,
                entity_id: primaryEntity?.entityId
                // payment_status: "pending",
                // service_name: selectedService.serviceName,

            };

            const response = await addService({ serviceId: selectedService.serviceId, ...postData }).unwrap();



            //storing subscriptionId inside redux file 
            // const subscriptionId = response.data?.subscription_id;

            // await getAllServices({ entity_id: primaryEntity?.entityId, page: 1, perPage: DEFAULT_PAGE_SIZE }).unwrap();
            onSuccess(response.data?.subscription_id);


        } catch (error) {
            console.error('Failed to add service:', error);
        }
    };

    return (
        <OverlayRegular>
            <div className={styles.services_added_popup}>
                <div className={styles.new_popup_header}>
                    <div className={styles.new_popup_title}>Service Added</div>
                    <button className={styles.btn__close} onClick={onClose}>
                        <img src={closeIcon} alt="Close" />
                    </button>
                </div>
                {/*middle container */}
                <div className={styles.service_box}>

                    {/* Entity Name */}
                    <p>Entity : <span className={styles.entity_name_dynamic}>{primaryEntity?.entityName}</span></p>

                    {/* Horizantal line */}
                    <hr className={styles.horizantal_line} />

                    {/* service name */}
                    <div className={styles.s_name_year}>
                        <div className={styles.service_cont}>
                            <p className={styles.s_name}>{selectedService.serviceName}</p>
                            <div className={styles.green_box}>
                                <div className={styles.service_box_year}>
                                    {selectedService.serviceName === "Tax Residency Certificate" && (
                                        <p>{selectedService?.data?.selectedYear}</p>
                                    )}
                                    {selectedService.serviceName === "Audit & Review Report" && (
                                        <>
                                            <p>{selectedService?.data?.auditFromMonth}</p>
                                            <p>‘{selectedService?.data?.auditFromYear?.toString().slice(-2)}</p> {"  -  "}
                                            <p>{selectedService?.data?.auditToMonth}</p>
                                            <p>‘{selectedService?.data?.auditToYear?.toString().slice(-2)}</p>
                                        </>
                                    )}
                                </div>

                            </div>
                        </div>

                        <p className={styles.service_box_price}>${selectedService.servicePrice}</p>
                    </div>
                    {/* ends here */}
                </div>

                {/*Remove and Pay Button */}
                <div className={styles.np_footer}>
                    <button className={styles.remove_btn} onClick={onClose}>X REMOVE</button>
                    <button className={styles.pay_btn} onClick={handlePayClick}>PAY <img src={arrowWhite} alt="right arrow" /> </button>
                </div>
            </div>
        </OverlayRegular>
    );
};

export default ServicesAdded;









