import React, { useState, useEffect } from "react";
import OverlayRegular from "../../../UI/Modal/OverlayRegular";
import styles from "./PaymentDetails.module.css";
import copyIcon from "/assets/subscription/Copy.svg";
import ToggleButton from "../../../UI/ToggleButton/ToggleButton";
import { motion } from "framer-motion";
import closeIcon from "/assets/Close.svg";
import backArrow from "/assets/common/BackArrow_Icon.svg";
import PaymentStatus from "./PaymentStatus";
import { useGetBankAccountsQuery } from "../apis/serviceApi-slice";
import { useSelector } from "react-redux";

const PaymentDetails = ({
  option,
  onClose,
  onBack,
  selectedFile,
  children,
  onChangeBank,
}) => {
  const [bankType, setBankType] = useState(
    option === "U.S.A" ? "Mercury | Brex" : "India"
  );
  const { bankAccounts } = useSelector((state) => state.services);
  const [detailsData, setDetailsData] = useState(bankAccounts[bankType]);
  const { isLoading, isError } = useGetBankAccountsQuery();

  useEffect(() => {
    onChangeBank(detailsData?.bank_id);
  }, [detailsData]);

  useEffect(() => {
    setDetailsData(bankAccounts[bankType]);
  }, [bankAccounts, bankType]);

  const handleCopyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
  };

  return (
    <OverlayRegular>
      <div
        className={`${styles.wrapper} ${
          option === "U.S.A" ? styles.wrapper_usa : styles.wrapper_india
        }`}
      >
        <div className={styles.payment_header}>
          <button className={styles.back_btn_cont} onClick={onBack}>
            <img src={backArrow} alt="Back" />
            <p className={styles.back_btn}>Back</p>
          </button>
          <PaymentStatus fileUploaded={!!selectedFile} />
          <button className={styles.btn__close} onClick={onClose}>
            <img src={closeIcon} alt="Close" />
          </button>
        </div>

        {option === "U.S.A" && (
          <>
            <div className={styles.label_toggle}>Choose your US Bank</div>
            <ToggleButton
              className={styles.toggle}
              titleLeft="Mercury | Brex"
              titleRight="Other Banks"
              active={bankType}
              onToggle={setBankType}
            />
          </>
        )}

        {/* Show International Wire Transfer if option is "U.S.A" and not "Other Banks" */}
        {option === "U.S.A" && bankType !== "Other Banks" && (
          <div className={styles.inter_wire_cont}>
            <div>
              <span>International Wire Transfer</span>
              (No additional charges)
            </div>
            <p>Please transfer to the below account & attach receipt</p>
          </div>
        )}

        {/* Show ACH Transfer if option is "Other Banks" */}
        {bankType === "Other Banks" && (
          <div className={styles.inter_wire_cont}>
            <div>
              <span>ACH Transfer</span>
              (Additional charges may apply)
              <p>Please transfer to the below account & attach receipt</p>
            </div>
          </div>
        )}

        {/* Show Indian Bank Details if option is "India" */}
        {option === "India" && (
          <div className={styles.india_instructions}>
            <h1 className={styles.indian_instructions_head}>
              Indian Bank Details
            </h1>
            <p className={styles.indian_instructions_para}>
              Please transfer to the below account & attach receipt
            </p>
          </div>
        )}

        <div
          className={`${styles.middle_cont} ${
            option === "India" ? styles.india_middle_cont : ""
          }`}
        >
          <div
            className={`${styles.payment_box_common} ${
              option === "India"
                ? styles.payment_box_india
                : styles.payment_box_usa
            }`}
          >
            <div className={styles.table_details}>
              {detailsData?.data?.map((item) => (
                <div key={item.title} className={styles.detail_row}>
                  <div className={styles.title}>{item.title}</div>
                  <div className={styles.detail}>
                    <div>:&nbsp;&nbsp;{item.detail}&nbsp;&nbsp;</div>
                    {item.copyToClipboard && (
                      <motion.button
                        animate={{ scale: 1 }}
                        whileTap={{ scale: 1.5 }}
                        transition={{ duration: 0.6 }}
                        onClick={() => handleCopyToClipboard(item.detail)}
                      >
                        <img src={copyIcon} alt="Copy" />
                      </motion.button>
                    )}
                    {item.suggestion && (
                      <div>&nbsp;&nbsp;{item.suggestion}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {React.cloneElement(children)}
        </div>

        <div
          className={`${styles.footer_link} ${
            option === "India" ? styles.footer_link_india : ""
          }`}
        >
          <div className={styles.footer_label}>Login to your Bank A/C:</div>
          <div className={styles.external_links}>
            {detailsData?.linkData?.map((item) => (
              <>
                {item.id == "Other" && (
                  <div onClick={() => window.open(item.path, "_blank")} className={styles.link_other_bank}>
                    <img src={item.image} alt="" />
                  </div>
                )}
                {item.id != "Other" && (
                  <a
                    href={item.path}
                    key={item.id}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={item.image} alt={`${item.id} Bank Link`} />
                  </a>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </OverlayRegular>
  );
};

export default PaymentDetails;
