import styles from "./ReadPolicyAndTerms.module.css";
import closeIcon from "/assets/Close.svg";
import ModalLarge from "../UI/Modal/ModalLarge.jsx";

const ReadPolicyAndTerms = ({ title, content, ...props }) => {
  return (
    <ModalLarge onClose={props.onClose}>
      <div className={styles.container}>
        <button className={styles.btn__close} onClick={props.onClose}>
          <img src={closeIcon} alt="" />
        </button>
        <div className={styles.title}>{title}</div>
        <div className={styles.content_wrapper}>
          <div className={styles.content}>{content}</div>
        </div>
      </div>
    </ModalLarge>
  );
};

export default ReadPolicyAndTerms;
