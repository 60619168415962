import { useDispatch, useSelector } from "react-redux";
import styles from "./ForeignStatesDetails.module.css";
import { EntityDetail } from "./PrimaryEntityDetails";
import {
  TITLE__FORIGN_QUALIFICATION,
  LBL__ADD_FOREIGN_STATE,
  LBL__STATE_ID,
  LBL__BUSINESS_ADDRESS,
  LBL__REGISTRATION_CERTIFICATE,
  LBL__NEXUS_TYPE,
} from "../../../../consts/TextConstants";
import addIcon from "/assets/common/AddIcon_Blue.svg";
import editIcon from "/assets/common/EditIcon.svg";
import addIconWhite from "/assets/common/Plus_White.svg";
import { useState } from "react";
import { entityActions } from "../reducers/entity-slice";
import { entityEditForms } from "../../../../consts/Constants";
import EntityEditPage from "../EntityEdit/EntityEditPage";
import { AnimatePresence } from "framer-motion";
import { useGetForeignStatesQuery, useLazyGetForeignStatesQuery } from "../apis/entityApi-slice";
import { getShortFileName } from "../../../../utils/common-utils";
import {
  BASE_URL,
  URL_GET_ENTITY_DOCUMENTS,
} from "../../../../consts/APIConstants";
import moreInfoIcon from "/assets/common/MoreInfo.svg";
import { downloadFile } from "../../../../utils/fileHandler";
import ButtonCustom from "../../../UI/Button/ButtonCustom";
import LoadingComponent from "../../../UI/LoadingIndicator/LoadingComponent";

const ForeignState = ({ selected = false, stateValue, isAddBtn, ...props }) => {
  return (
    <div
      className={`${styles.foreign_state} ${
        isAddBtn ? styles.btn_add_initial : ""
      } ${selected ? styles.foreign_state_selected : ""}
     `}
      {...props}
    >
      {stateValue}
      {isAddBtn && <img src={addIcon} alt="" />}
    </div>
  );
};

const ForeignStatesDetails = () => {
  const [openEditOverlay, setOpenEditOverlay] = useState(false);
  const { foreignStates, selectedForeignState, selectedEntity } = useSelector(
    (state) => state.entity
  );
  const { isSuccess, isFetching:isLoading, error } = useGetForeignStatesQuery(
    {
      entity_id: selectedEntity?.entityId,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [getForeignState, {isFetching: isLoadingonTrigger, error: erroronTrigger}] = useLazyGetForeignStatesQuery();

  const stateInfo = foreignStates ? foreignStates[selectedForeignState] : null;
  const dispatch = useDispatch();

  const handleClickAdd = () => {
    dispatch(entityActions.setEditForm(entityEditForms.FOREIGN));
    setOpenEditOverlay(true);
  };

  const handleClickEdit = (event, index) => {
    if (index == undefined) {
      dispatch(entityActions.setEditForm(entityEditForms.FOREIGN));
      setOpenEditOverlay(true);
    } else {
      dispatch(entityActions.setSelectedForeignState(index));
    }
  };

  const handleEditClose = (event, showLastUpdate = false) => {
    if (showLastUpdate) {
      dispatch(entityActions.setSelectedForeignState(foreignStates.length));
    }
    setOpenEditOverlay(false);
  };

  const handleSuccess = async () => {
    try {
      await getForeignState({
        entity_id: selectedEntity?.entityId,
      }).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadFile = async () => {
    const fileName = stateInfo?.registrationCertificate;
    if (fileName == null || fileName == undefined) {
      return;
    }
    try {
      const fileDownloadURL = BASE_URL + URL_GET_ENTITY_DOCUMENTS;

      const queryparams = `?entity_id=${encodeURIComponent(
        selectedEntity?.entityId
      )}&file_name=${encodeURIComponent(
        fileName
      )}&field_name=registeration_certificate_document`;

      downloadFile(fileDownloadURL, queryparams, fileName);
    } catch (err) {
      console.log(err);
    }
  };

  let nexusTypesContent = (
    <>
      {stateInfo?.nexusTypes.map((item) => (
        <div key={`nexus-type-${item}`} className={styles.nexus_type}>
          {item}
        </div>
      ))}
    </>
  );

  if(isLoading || isLoadingonTrigger) {
    return <LoadingComponent type="default" className={styles.loading_section} />
  }

  if((error && error?.status !== 404) || (erroronTrigger && erroronTrigger?.status !== 404)) {
    return <div/>
  }

  return (
    <section className={styles.foreign_section}>

      <div className={styles.section_header}>
        {TITLE__FORIGN_QUALIFICATION}
        <div className={styles.img_more_info}>
          <img src={moreInfoIcon} alt="" />
        </div>
      </div>

      <div className={styles.header_List}>
        <div className={styles.foreign_states_list}>
          {foreignStates.length == 0 && (
            <ForeignState
              isAddBtn={true}
              stateValue={LBL__ADD_FOREIGN_STATE}
              onClick={handleClickAdd}
            />
          )}
          {foreignStates.map((item, index) => (
            <ForeignState
              key={`${item.state}-${index}`}
              stateValue={item.state}
              selected={index == selectedForeignState}
              onClick={(event) => handleClickEdit(event, index)}
            />
          ))}
        </div>

       { foreignStates?.length > 0 && <ButtonCustom className={styles.btn__add} onClick={handleClickAdd}>
          <img src={addIconWhite} alt="" />
          ADD STATE
        </ButtonCustom>}
      </div>

      <div className={styles.section_content}>
        {(selectedEntity?.hasOtherState || foreignStates.length > 0) && (
          <>
            <EntityDetail
              title={LBL__NEXUS_TYPE}
              value={nexusTypesContent || "NA"}
            />

            <EntityDetail
              title={LBL__STATE_ID}
              value={stateInfo?.stateId || "NA"}
            />

            <EntityDetail
              title={LBL__BUSINESS_ADDRESS}
              value={stateInfo?.businessAddress || "NA"}
            />

            <EntityDetail
              title={LBL__REGISTRATION_CERTIFICATE}
              value={`${stateInfo?.registrationCertificate ? "Download" : "NA"}`}
              type={`${stateInfo?.isRegisteredInState ? "file" : ""}`}
              filename={stateInfo?.registrationCertificate}
              onClick={handleDownloadFile}
            />
          </>
        )}
      </div>

      <AnimatePresence>
        {openEditOverlay && (
          <EntityEditPage
            onClose={handleEditClose}
            defaultValue={stateInfo}
            className={styles.overlay}
            onSuccess={handleSuccess}
          />
        )}
      </AnimatePresence>

    </section>
  );
};

export default ForeignStatesDetails;
