import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { entityActions } from "../reducers/entity-slice";
import styles from "./AddBoardDetails.module.css";
import EditFormInputs from "../../Edit/EditFormInputs";
import EditInputItem from "../../Edit/EditInputItem";
import EditFormActionGroup from "../../Edit/EditFormActionGroup";
import {
  LBL__NAME,
  LBL__TITLE,
  LBL__ALSO_OFFICER,
  LIST__OFFICER_TITLES,
  BTN__SUBMIT,
  BTN__RETRY,
} from "../../../../consts/TextConstants";
import { AnimatePresence } from "framer-motion";
import useInput from "../../../../hooks/use-input";
import { isNotEmpty } from "../../../../utils/validations";
import { entityEditForms } from "../../../../consts/Constants";
import InputCheckBox from "../../../UI/Input/InputCheckBox";
import { motion } from "framer-motion";
import EditAddressInputs from "../../Edit/EditAddressInputs";
import {
  useCreateBoardMemberMutation,
  useLazyGetBoardMembersQuery,
} from "../apis/entityApi-slice";
import MessageDialog from "../../../UI/Modal/MessageDialog";
import {
  ERROR_MSG__TITLE,
  ERROR_MSG__NAME,
} from "../../../../consts/ErrorMessages";

const AddBoardDetails = ({ editForm, onSuccess, ...props }) => {
  const [error, setError] = useState({ isError: false });
  const [showTitleError, setShowTitleError] = useState(false);
  const [showBusinessAddressError, setShowBusinessAddressError] =
    useState(false);

  const [isAlsoOfficer, setIsAlsoOfficer] = useState(false);
  const [title, setTitle] = useState("");
  const [businessAddress, setBusinessAddress] = useState({
    address: "",
    isValid: false,
  });
  const { selectedEntity } = useSelector((state) => state.entity);
  const dispatch = useDispatch();

  const [createBoardMember, { isLoading }] = useCreateBoardMemberMutation();
  // const [getBoardMembers, { isLoading: isGetMembersLoading }] =
  //   useLazyGetBoardMembersQuery();

  const {
    value: name,
    isValid: isNameValid,
    showError: showNameError,
    handleChange: handleNameChange,
    handleBlur: handleNameBlur,
    handleFocus: handleNameFocus,
    setShowError: setShowNameError,
  } = useInput(isNotEmpty);

  const handleAlsoOfficerChange = (event) => {
    setIsAlsoOfficer(event.target.checked);
  };

  const validateForm = () => {
    if (
      !businessAddress.isValid ||
      !isNameValid ||
      ((isAlsoOfficer || editForm == entityEditForms.BOARD_OFFICERS) && !title)
    ) {
      setShowBusinessAddressError(!businessAddress.isValid);
      setShowTitleError(
        (isAlsoOfficer || editForm == entityEditForms.BOARD_OFFICERS) && !title
      );
      setShowNameError(!isNameValid);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const requestData = {
        entity_id: selectedEntity?.entityId,
        board_member_name: name,
        board_member_title: title,
        isOfficer: editForm == entityEditForms.BOARD_OFFICERS || isAlsoOfficer,
        isDirector: editForm == entityEditForms.BOARD_DIRECTORS,
        board_business_street: businessAddress.addressParams.street,
        board_business_city: businessAddress.addressParams.city,
        board_business_state: businessAddress.addressParams.state,
        board_business_country: businessAddress.addressParams.country,
        board_business_zip: businessAddress.addressParams.zipCode,
      };
      await createBoardMember(requestData).unwrap();
      onSuccess();
      props.onClose();
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };

  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  return (
    <>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle={BTN__RETRY}
          onClose={handleCloseError}
        />
      )}
      <EditFormInputs key="board-part-one">
        <EditInputItem
          name="name"
          labelTitle={LBL__NAME}
          onChange={handleNameChange}
          onBlur={handleNameBlur}
          onFocus={handleNameFocus}
          value={name}
          showError={showNameError}
          errorMessage={ERROR_MSG__NAME}
        />
        {editForm == entityEditForms.BOARD_OFFICERS && (
          <EditInputItem
            name="title"
            type="dropdown"
            labelTitle={LBL__TITLE}
            onChange={(value) => {
              setTitle(value);
              setShowTitleError(false);
            }}
            value={title}
            options={LIST__OFFICER_TITLES}
            showError={showTitleError}
            errorMessage={ERROR_MSG__TITLE}
          />
        )}
        <AnimatePresence>
          {editForm == entityEditForms.BOARD_DIRECTORS && (
            <motion.div className={styles.checkbox_wrapper} layout>
              <InputCheckBox
                title={LBL__ALSO_OFFICER}
                onChange={handleAlsoOfficerChange}
                className={`${isAlsoOfficer ? styles.checkbox : ""}`}
                labelStyle={styles.label_checkbox}
                checked={isAlsoOfficer}
              />
              <AnimatePresence>
                {isAlsoOfficer && (
                  <EditInputItem
                    name="title"
                    type="dropdown"
                    className={styles.title_dropdown}
                    labelTitle={LBL__TITLE}
                    onChange={(value) => {
                      setTitle(value);
                      setShowTitleError(false);
                    }}
                    value={title}
                    options={LIST__OFFICER_TITLES}
                    showError={showTitleError}
                    errorMessage={ERROR_MSG__TITLE}
                  />
                )}
              </AnimatePresence>
            </motion.div>
          )}
        </AnimatePresence>
        <EditAddressInputs
          onChange={(value) => {
            setBusinessAddress(value);
            if (value.isValid) {
              setShowBusinessAddressError(false);
            }
          }}
          defaultValue={businessAddress.addressParams}
          name="businessAddress"
          showError={showBusinessAddressError}
        />
      </EditFormInputs>
      <EditFormActionGroup
        primaryTitle={BTN__SUBMIT}
        onClick={handleSubmit}
        isLoading={isLoading}
        // disabled={
        //   !businessAddress.isValid ||
        //   !isNameValid ||
        //   ((isAlsoOfficer || editForm == entityEditForms.BOARD_OFFICERS) &&
        //     !title)
        // }
      />
    </>
  );
};

export default AddBoardDetails;
