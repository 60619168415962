import styles from "./ConfirmationDialog.module.css";
import { motion } from "framer-motion";
import BackDrop from "./Backdrop";
import ButtonCustom from "../Button/ButtonCustom";
import { LBL__YES, LBL__NO } from "../../../consts/TextConstants";

const ConfirmationDialog = ({ open, message, onConfirm, ...props }) => {
  const overlay = {
    hidden: { opacity: 0, y: "40rem" },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <BackDrop
      className={styles.backdrop}
      onClose={props.onClose}
      blurValue={"0.25rem"}
    >
      <motion.div
        key="overlay-error"
        // variants={overlay}
        // initial="hidden"
        // animate="visible"
        // exit="hidden"
        // transition={{
        //   ease: "easeInOut",
        //   duration: 0.6,
        //   type: "spring",
        //   stiffness: 100,
        // }}
        className={styles.main}
      >
        <p className={styles.message}>{message}</p>
        <div className={styles.btn__group}>
          <ButtonCustom
            className={`${styles.ui__btn} ${styles.btn_plain}`}
            onClick={props.onClose}
          >
            {LBL__NO}
          </ButtonCustom>
          <ButtonCustom className={styles.ui__btn} onClick={onConfirm}>
            {LBL__YES}
          </ButtonCustom>
        </div>
      </motion.div>
    </BackDrop>
  );
};

export default ConfirmationDialog;
