import styles from "./Balance.module.css";
import refreshIcon from "/assets/common/Refresh.svg";
import editIcon from "/assets/common/EditPen.svg";
import arrowIcon from "/assets/common/ArrowDown.svg";
import linkIcon from "/assets/finance/Link_Blue.svg";
import { useSelector } from "react-redux";
import MoreInfo from "../../UI/General/MoreInfo";
import { useLazyGetAccountBalanceQuery } from "./apis/financeApi-slice";
import { REDIRECT_URL__ZOHO } from "../../../consts/APIConstants";
import MaskedText from "../../UI/Text/MaskedText";
import { isValidElement, useEffect, useState } from "react";
import BusinessAccountsOverlay from "./BusinessAccountsOverlay";
import {
  addNumberOfMonthsToDate,
  getCurrencyWithSymbol,
} from "../../../utils/common-utils";
import { isValid } from "../../../utils/validations";

const formatAccountBalance = (balance, currencyCode) => {
  const balanceWithCurrency = getCurrencyWithSymbol(currencyCode, balance);

  return (
    balanceWithCurrency.substring(0, 1) + " " + balanceWithCurrency.substring(1)
  );
};

const Balance = () => {
  const { totalBalance, bankAccounts, runway, baseCurrencySymbol } =
    useSelector((state) => state.finance);
  const { primaryEntity } = useSelector((state) => state.entity);
  const [openBusinessAccounts, setOpenBusinessAccounts] = useState(false);

  const [getAccountBalance] = useLazyGetAccountBalanceQuery();

  const handleRefreshClick = async () => {
    try {
      await getAccountBalance({
        entity_id: primaryEntity?.entityId,
        redirect_uri: REDIRECT_URL__ZOHO,
      }).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          Balance <MoreInfo content="Based on connected account" />
        </div>

        {/* Enable it for phase 1.1 */}
        {/* <button
          className={styles.btn__edit}
          onClick={() => setOpenBusinessAccounts(true)}
        >
          <img src={editIcon} alt="" />
        </button> */}
      </div>
      <div className={styles.balance}>
        {`${baseCurrencySymbol} ${totalBalance.toLocaleString()}`}
        <button className={styles.btn__refresh} onClick={handleRefreshClick}>
          <img src={refreshIcon} alt="" />
        </button>
      </div>
      {runway && (
        <div className={styles.runaway}>
          <div className={styles.title}>
            Runway <MoreInfo content="Based on the current cash burn rate" />
          </div>
          <div className={styles.runaway_description}>
            {runway} Months
            <span>{addNumberOfMonthsToDate(new Date(), runway)}</span>
          </div>
        </div>
      )}
      <ul className={styles.list_accounts}>
        {bankAccounts.slice(0, 2).map((item, index) => (
          <li key={`account-${item.accountNumber}-${index}`}>
            <div className={styles.account_list_item_left}>
              <div className={styles.linked_account}>
                <img src={linkIcon} alt="" />
              </div>
              {item.accountNumber ? (
                <MaskedText
                  value={item.accountNumber}
                  showFixedMaskChar={true}
                  maskedCharacters={3}
                />
              ) : (
                <div>{item.accountName}</div>
              )}
            </div>

            <div className={styles.account_balance}>{`${formatAccountBalance(
              item.balance,
              item.currencyCode
            )}`}</div>
          </li>
        ))}
      </ul>
      <button
        className={styles.btn__more_details}
        onClick={() => setOpenBusinessAccounts(true)}
      >
        <img src={arrowIcon} alt="" />
      </button>

      {openBusinessAccounts && (
        <BusinessAccountsOverlay
          onClose={() => setOpenBusinessAccounts(false)}
        />
      )}
    </div>
  );
};

export default Balance;
