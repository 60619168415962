
import React from 'react';
import styles from './ServiceTypes.module.css';
import rightArrowBlack from "/assets/subscription/links/ArrowRight_Black.svg";
import rightArrowBlue from "/assets/subscription/links/ArrowRight_Blue.svg"; // Blue arrow for selected services

const ServiceTypes = ({ selectedService, searchQuery, handleServiceClick, services }) => {
    const filteredServices = services.filter(service =>
        service.serviceName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className={styles.left_container}>
            <ul>
                {filteredServices.length > 0 ? (
                    filteredServices.map((service) => (
                        <li
                            key={service.serviceId}
                            className={`${styles.clip} ${selectedService && service.serviceId === selectedService.serviceId ? styles.selected : ''}`}
                            onClick={() => handleServiceClick(service)}
                        >
                            {service.serviceName}
                            <img
                                src={selectedService && service.serviceId === selectedService.serviceId ? rightArrowBlue : rightArrowBlack}
                                alt="right arrow"
                                className={styles.right_arrow}
                            />
                        </li>
                    ))
                ) : (
                    <li className={styles.no_results}>No services found</li>
                )}
            </ul>
        </div>
    );
};

export default ServiceTypes;


