
import React from 'react';
import OverlayRegular from '../../../UI/Modal/OverlayRegular';
import styles from './PaymentSuccessPage.module.css';
import closeIcon from "/assets/Close.svg";
import merzeLogo from "/assets/subscription/links/logo_merze.svg";
import charmTickIcon from "/assets/subscription/links/Charmtick.svg";
import gifAnimation from "/assets/subscription/links/GifAnimations.gif";

const SuccessPage = ({ onClose }) => {
    const handleSubmit = () => {
        onClose();

    };
    return (
        <OverlayRegular>
            <div className={styles.final_page_container}>

                <button className={styles.btn__close} onClick={handleSubmit}>
                    <img src={closeIcon} alt="Close" />
                </button>

                <div className={styles.design_center_cont}>
                    <div className={styles.pay_design_cont}>
                        <div className={styles.two}>
                            <div className={styles.circle_with_label}>
                                <div className={styles.first_circle}>
                                    <img src={charmTickIcon} className={styles.charm_tick} />
                                </div>
                            </div>
                            <div className={styles.hori_line_one}></div>
                            <div className={styles.circle_with_label}>
                                <div className={styles.second_circle}>
                                    <img src={charmTickIcon} className={styles.charm_tick} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.three}>
                            <div className={`${styles.label} ${styles.payment_label}`}>Payment</div>
                            <div className={`${styles.label} ${styles.payment_label}`}>Confirmation</div>
                        </div>
                    </div>
                </div>

                <div className={styles.merze_logo_cont}>
                    <img src={merzeLogo} className={styles.logo_img} />
                    <p className={styles.logo_name}>Merze</p>
                </div>
                <div className={styles.done_icon}><img src={gifAnimation} alt="Success Animation" className={styles.gif_style} /></div>
                <div className={styles.thanks_cont}>
                    <p className={styles.thanks_para}>Thanks For Making the Payment</p>
                </div>
                <p className={styles.verified_para}>Payment is being verified</p>
                <button className={styles.ok_btn} onClick={handleSubmit}>OKAY</button>

            </div>
        </OverlayRegular>
    );
};

export default SuccessPage;







