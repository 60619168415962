import styles from "./Button.module.css";
import { motion } from "framer-motion";
import LoadingIndicator from "./LoadingIndicator/LoadingIndicator";

const Button = ({ children, className, buttonstyle = "filled", ...props }) => {
  let style = styles.ui__button_filled;

  if (buttonstyle == "no-fill") {
    style = styles.ui__button_no_fill;
  } else if (buttonstyle == "plain") {
    style = styles.ui__button_plain;
  }

  const classes = `${styles.ui__button} ${style} ${className}`;

  return (
    <motion.button
      whileHover={{
        backgroundColor: "rgba(0,0,0,0)",
        color: "#0047FF",
        borderWidth: "3px",
      }}
      transition={{ duration: 0.3 }}
      className={classes}
      {...props}
    >
      {props.isLoading && <LoadingIndicator />}
      {children}
    </motion.button>
  );
};

export default Button;
