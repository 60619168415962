import { useSelector } from "react-redux";
import styles from "./DashboardContent.module.css";
import Header from './DashboardHeader.jsx';
import OngoingTasks from "./OngoingTasks.jsx";
import CompleteTasks from "./CompleteTasks.jsx";
import RecentActivity from "./FooterCont.jsx";
import dashboardImg from "/assets/dashboard/Temp_Upcoming_deadlines.svg";

const DashboardContent = () => {
  const { primaryEntity } = useSelector((state) => state.entity);
  return (
    <div className={styles.container}>
      {/* <section className={styles.entity}>
        <div className={styles.entity_name}>{primaryEntity?.entityName}</div>
        <div className={styles.entity_details}>
          <div className={styles.entity_type}>{primaryEntity?.entityType}</div>
          <div className={styles.entity_state}>{primaryEntity?.incorporationState}</div>
        </div>
      </section>
      <section className={styles.shareholding}></section>
      <section className={styles.action_items}></section>
      <section className={styles.compliance}></section>
      <section className={styles.graph}></section>
      <section className={styles.promotional}></section> */}
      <div className={styles.dashboard_cont}>
        <Header />
        <div className={styles.middle_cont}>
          {/* <OngoingTasks />
          <CompleteTasks /> */}
          <img src={dashboardImg} alt="" />
        </div>
        {/* <div className={styles.footer_cont}><RecentActivity /></div> */}
      </div>
    </div>
  );
};

export default DashboardContent;
