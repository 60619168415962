import styles from "./ButtonWithIcon.module.css";

const ButtonWithIcon = ({ children, className, icon, ...props }) => {
  return (
    <button className={`${styles.ui__custom_button} ${className}`} {...props}>
      <img src={icon} alt="" />
      {children}
    </button>
  );
};

export default ButtonWithIcon;
