import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./AddForeignState.module.css";
import EditFormInputs from "../../Edit/EditFormInputs";
import EditInputItem from "../../Edit/EditInputItem";
import EditFormActionGroup from "../../Edit/EditFormActionGroup";
import {
  BTN__NEXT,
  LBL__REGISTERED_AGENT_NAME,
  BTN__BACK,
  LBL__AGENT_INFO,
  LBL__ADD_FOREIGN_STATE,
  LBL__STATE_ID,
  LBL__AGENT_ADDRESS,
  BTN__SUBMIT,
  LBL__YES,
  LBL__NO,
  LBL__BUSINESS_ADDRESS,
  LBL__REGISTRATION_CERTIFICATE,
  BTN__RETRY,
  LBL__SELECT_STATE,
  LBL__OVERLAY_NEXUS_TYPE,
  LBL__REGISTRATION_DATE,
} from "../../../../consts/TextConstants";
import { AnimatePresence } from "framer-motion";
import {
  US_STATES_LIST,
  getFilteredUSStatesList,
} from "../../../../utils/listUSStates";
import useInput from "../../../../hooks/use-input";
import { isNotEmpty, isAlphaNumeric } from "../../../../utils/validations";
import EditAddressInputs from "../../Edit/EditAddressInputs";
import InputCheckBox from "../../../UI/Input/InputCheckBox";
import InputRadio from "../../../UI/Input/InputRadio";
import { entityNexusTypes } from "../../../../consts/Constants";
import MessageDialog from "../../../UI/Modal/MessageDialog";
import { PAYLD__CREATE_FOREIGN_STATE } from "../../../../consts/PayloadConstants";
import { useCreateForeignStateMutation } from "../apis/entityApi-slice";
import { formatRequestData } from "../../../../utils/dataFormatter";
import {
  ERROR_MSG__SELECT_STATE,
  ERROR_MSG__NAME,
  ERROR_MSG__ONLY_NUMBERS,
  ERROR_MSG__NEXUS_TYPE,
  ERROR_MSG__FILE_REQUIRED,
  ERROR_MSG__SELECT_YES_NO,
} from "../../../../consts/ErrorMessages";
import { convertToISODate } from "../../../../utils/common-utils";
let existingForeignStates = [];
const InputNexusType = ({ labelTitle, checked, onChange }) => {
  return (
    <div className={styles.input_nexus_type}>
      <InputCheckBox
        title={labelTitle}
        onChange={onChange}
        className={styles.input_checkbox}
        labelStyle={styles.label_checkbox}
        checkBoxStyle={styles.checkbox}
        checked={checked}
      />
    </div>
  );
};

const AddForeignState = ({ onSuccess, ...props }) => {
  const [error, setError] = useState({ isError: false });
  const [showForeignStateError, setShowForeignStateError] = useState(false);
  const [showAgentAddressError, setShowAgentAddressError] = useState(false);
  const [
    showRegistrationCertificateError,
    setShowRegistrationCertificateError,
  ] = useState(false);
  const [showBusinessAddressError, setShowBusinessAddressError] =
    useState(false);
  const [showNexusTypesError, setShowNexusTypesError] = useState(false);
  const [showRegisteredInStateError, setShowRegisteredInStateError] =
    useState(false);

  const [registrationDate, setRegistrationDate] = useState(new Date());
  const [foreignState, setForeignState] = useState("");
  const [isRegisteredInState, setIsRegisteredInState] = useState(null);
  const [hasPhysicalOffice, setHasPhysicalOffice] = useState(false);
  const [registrationCertificate, setRegistrationCertificate] = useState(null);
  const [nexusTypes, setNexusTypes] = useState([]);
  const [businessAddress, setBusinessAddress] = useState({
    address: "",
    isValid: false,
  });
  const [agentAddress, setAgentAddress] = useState({
    address: "",
    isValid: false,
  });
  const [currentForm, setCurrentForm] = useState(1);

  const { selectedEntity, foreignStates } = useSelector(
    (state) => state.entity
  );

  const [createForeignState, { isLoading }] = useCreateForeignStateMutation();

  const {
    value: stateId,
    isValid: isStateIdValid,
    showError: showStateIdError,
    handleChange: handleStateIdChange,
    handleBlur: handleStateIdBlur,
    handleFocus: handleStateIdFocus,
    setShowError: setShowStateIdError,
  } = useInput(isAlphaNumeric, "");

  const {
    value: agentName,
    isValid: isAgentNameValid,
    showError: showAgentNameError,
    handleChange: handleAgentNameChange,
    handleBlur: handleAgentNameBlur,
    handleFocus: handleAgentNameFocus,
    setShowError: setShowAgentNameError,
  } = useInput(isNotEmpty, "");

  const handleForeignStateChange = (selectedState) => {
    setForeignState(selectedState.key);
    setShowForeignStateError(false);
  };

  const validateForm = () => {
    if (
      !foreignState ||
      !(isRegisteredInState || nexusTypes.length != 0) ||
      (isRegisteredInState && (!isStateIdValid || !registrationCertificate)) ||
      isRegisteredInState == null
    ) {
      setShowRegisteredInStateError(isRegisteredInState == null);
      setShowNexusTypesError(!(isRegisteredInState || nexusTypes.length != 0));
      setShowForeignStateError(!foreignState);
      setShowRegistrationCertificateError(!registrationCertificate);
      setShowStateIdError(!isStateIdValid);
      return false;
    } else if (
      currentForm == 2 &&
      hasPhysicalOffice &&
      !businessAddress.isValid
    ) {
      setShowBusinessAddressError(!businessAddress.isValid);
      return false;
    } else if (
      currentForm == 3 &&
      isRegisteredInState &&
      (!isAgentNameValid || !agentAddress.isValid)
    ) {
      setShowAgentAddressError(!agentAddress.isValid);
      setShowAgentNameError(!isAgentNameValid);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    if (currentForm != 3) {
      if (isRegisteredInState || (hasPhysicalOffice && currentForm == 1)) {
        if (hasPhysicalOffice && !isRegisteredInState) {
          props.changeFormTitle(LBL__BUSINESS_ADDRESS);
        }
        if (currentForm == 2) {
          props.changeFormTitle(LBL__AGENT_INFO);
        }
        setCurrentForm(currentForm + (hasPhysicalOffice ? 1 : 2));
        return;
      }
    }

    try {
      const files = isRegisteredInState
        ? ["registeration_certificate_document", registrationCertificate]
        : [];
      const requestData = formatRequestData(
        {
          entityId: selectedEntity?.entityId,
          ...(isRegisteredInState
            ? {
                stateId,
                agentName,
                registrationDate: convertToISODate(registrationDate),
                agentAddressStreet: agentAddress.addressParams.street,
                agentAddressCity: agentAddress.addressParams.city,
                agentAddressState: agentAddress.addressParams.state,
                agentAddressZip: agentAddress.addressParams.zipCode,
                agentAddressCountry: agentAddress.addressParams.country,
              }
            : {}),
          ...(hasPhysicalOffice
            ? {
                businessAddressStreet: businessAddress.addressParams.street,
                businessAddressCity: businessAddress.addressParams.city,
                businessAddressState: businessAddress.addressParams.state,
                businessAddressCountry: businessAddress.addressParams.country,
                businessAddressZip: businessAddress.addressParams.zipCode,
              }
            : {}),

          foreignState,
          nexusTypes: nexusTypes,
          isRegisteredInState,
        },
        files,
        PAYLD__CREATE_FOREIGN_STATE
      );

      await createForeignState(requestData).unwrap();
      onSuccess();
      props.onClose(null, true);
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };

  const handleSecondaryClick = () => {
    if (currentForm == 2 || !hasPhysicalOffice) {
      setCurrentForm(1);
      if (!isRegisteredInState) {
        props.changeFormTitle(LBL__ADD_FOREIGN_STATE);
      }
    } else {
      setCurrentForm(2);
      props.changeFormTitle(
        !isRegisteredInState ? LBL__BUSINESS_ADDRESS : LBL__ADD_FOREIGN_STATE
      );
    }
  };

  const handleChangeFileInput = (selectedFile) => {
    setRegistrationCertificate(selectedFile);
    setShowRegistrationCertificateError(!selectedFile);
  };

  const handleChangeNexusTypes = (nexusType) => {
    const index = nexusTypes.indexOf(nexusType);
    const newNexusTypesArray = [...nexusTypes];

    if (index > -1) {
      newNexusTypesArray.splice(index, 1);
    } else {
      newNexusTypesArray.push(nexusType);
    }
    if (nexusType == entityNexusTypes.PHYSICAL_OFFICE) {
      setHasPhysicalOffice(index == -1);
    }
    setNexusTypes(newNexusTypesArray);
    if (newNexusTypesArray.length > 0) {
      setShowNexusTypesError(false);
    }
  };

  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  useEffect(() => {
    let selectedForeignStates = [selectedEntity?.incorporationState];

    foreignStates.forEach((item) => {
      selectedForeignStates.push(item.state);
    });

    existingForeignStates = US_STATES_LIST.filter(function (item) {
      return this.indexOf(item.name) < 0;
    }, selectedForeignStates);
  }, []);

  useEffect(() => {
    setShowRegisteredInStateError(false);
    if (isRegisteredInState) {
      setShowNexusTypesError(false);
    }
  }, [isRegisteredInState]);

  let formInputsContent = (
    <EditFormInputs key="foreign-part-one">
      <div className={styles.container_state}>
        <EditInputItem
          name="incorporationState"
          type="autocomplete"
          labelTitle={LBL__SELECT_STATE}
          value={foreignState || ""}
          defaultValue={foreignState}
          customFilter={true}
          filteredListFunc={(value) =>
            getFilteredUSStatesList(value, existingForeignStates)
          }
          onChange={handleForeignStateChange}
          // autocompleteSize={styles.autocomplete}
          showError={showForeignStateError}
          errorMessage={ERROR_MSG__SELECT_STATE}
        />
      </div>

      <div className={styles.container_nexus_type}>
        <div className={styles.label}>{LBL__OVERLAY_NEXUS_TYPE}</div>
        <InputNexusType
          labelTitle="PHYSICAL OFFICE"
          onChange={() =>
            handleChangeNexusTypes(entityNexusTypes.PHYSICAL_OFFICE)
          }
          checked={nexusTypes.indexOf(entityNexusTypes.PHYSICAL_OFFICE) > -1}
        />
        <InputNexusType
          labelTitle="PAYROLL"
          onChange={() => handleChangeNexusTypes(entityNexusTypes.PAYROLL)}
          checked={nexusTypes.indexOf(entityNexusTypes.PAYROLL) > -1}
        />
        <InputNexusType
          labelTitle="REVENUE FROM STATE"
          onChange={() =>
            handleChangeNexusTypes(entityNexusTypes.REVENUE_FROM_STATE)
          }
          checked={nexusTypes.indexOf(entityNexusTypes.REVENUE_FROM_STATE) > -1}
        />
        {showNexusTypesError && (
          <div className={styles.error}>{ERROR_MSG__NEXUS_TYPE}</div>
        )}
      </div>
      <div className={styles.radio_registered_state}>
        <p className={styles.radio_registered_state_question}>
          {"Is the Business Registered in this State?"}
        </p>
        <div className={styles.radio_options}>
          <InputRadio
            radioStyle={styles.radio}
            radioSelectedStyle={styles.radio_selected}
            selected={isRegisteredInState == null ? "" : isRegisteredInState}
            label={LBL__YES}
            onSelect={() => setIsRegisteredInState(true)}
          />

          <InputRadio
            radioStyle={styles.radio}
            radioSelectedStyle={styles.radio_selected}
            selected={isRegisteredInState == null ? "" : !isRegisteredInState}
            label={LBL__NO}
            onSelect={() => setIsRegisteredInState(false)}
          />
        </div>
        {showRegisteredInStateError && (
          <div className={styles.error}>{ERROR_MSG__SELECT_YES_NO}</div>
        )}
      </div>
      {isRegisteredInState && (
        <>
          <EditInputItem
            name="stateId"
            labelTitle={LBL__STATE_ID}
            onChange={handleStateIdChange}
            onBlur={handleStateIdBlur}
            onFocus={handleStateIdFocus}
            value={stateId}
            showError={showStateIdError}
            errorMessage={ERROR_MSG__ONLY_NUMBERS}
          />
          <EditInputItem
            name="registrationCertificate"
            type="file"
            labelTitle={LBL__REGISTRATION_CERTIFICATE}
            onChange={handleChangeFileInput}
            value={registrationCertificate}
            showError={showRegistrationCertificateError}
            errorMessage={ERROR_MSG__FILE_REQUIRED}
          />

          <EditInputItem
            name="registrationDate"
            type="datepicker"
            labelTitle={LBL__REGISTRATION_DATE}
            onChange={(value) => setRegistrationDate(value)}
            value={registrationDate}
          />
        </>
      )}
    </EditFormInputs>
  );

  let actionContent = (
    <EditFormActionGroup
      primaryTitle={
        isRegisteredInState || hasPhysicalOffice ? BTN__NEXT : BTN__SUBMIT
      }
      onClick={handleSubmit}
      isLoading={isLoading}
      // disabled={
      //   !foreignState ||
      //   !(isRegisteredInState || nexusTypes.length != 0) ||
      //   isRegisteredInState == null
      // }
    />
  );

  if (currentForm == 2) {
    formInputsContent = (
      <EditFormInputs key="foreign-part-two">
        {hasPhysicalOffice && (
          <EditAddressInputs
            groupTitle={LBL__BUSINESS_ADDRESS}
            showGroupTitle={isRegisteredInState}
            onChange={(value) => setBusinessAddress(value)}
            defaultValue={businessAddress.addressParams}
            name="businessAddress"
            showError={showBusinessAddressError}
          />
        )}
      </EditFormInputs>
    );

    actionContent = (
      <EditFormActionGroup
        primaryTitle={isRegisteredInState ? BTN__NEXT : BTN__SUBMIT}
        secondaryTitle={BTN__BACK}
        onClickSecondary={handleSecondaryClick}
        onClick={handleSubmit}
        // disabled={
        //   (isRegisteredInState &&
        //     (!isStateIdValid || !registrationCertificate)) ||
        //   (hasPhysicalOffice && !businessAddress.isValid)
        // }
      />
    );
  }

  if (currentForm == 3) {
    formInputsContent = (
      <EditFormInputs key="foreign-part-three">
        <EditInputItem
          name="agentName"
          labelTitle={LBL__REGISTERED_AGENT_NAME}
          onChange={handleAgentNameChange}
          onBlur={handleAgentNameBlur}
          onFocus={handleAgentNameFocus}
          value={agentName}
          showError={showAgentNameError}
          errorMessage={ERROR_MSG__NAME}
        />
        <div />
        <EditAddressInputs
          groupTitle={LBL__AGENT_ADDRESS}
          onChange={(value) => setAgentAddress(value)}
          defaultValue={agentAddress.addressParams}
          name="agentAddress"
          lockedCountry={"United States"}
          lockedState={foreignState}
          showError={showAgentAddressError}
        />
      </EditFormInputs>
    );

    actionContent = (
      <EditFormActionGroup
        primaryTitle={BTN__SUBMIT}
        secondaryTitle={BTN__BACK}
        onClickSecondary={handleSecondaryClick}
        onClick={handleSubmit}
        isLoading={isLoading}
        // disabled={!isAgentNameValid || !agentAddress.isValid}
      />
    );
  }

  return (
    <>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle={BTN__RETRY}
          onClose={handleCloseError}
        />
      )}
      <AnimatePresence mode="wait">{formInputsContent}</AnimatePresence>
      <AnimatePresence mode="wait">{actionContent}</AnimatePresence>
    </>
  );
};

export default AddForeignState;
