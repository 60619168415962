import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./TRCApplicationForm.module.css";
import useInput from "../../../../hooks/use-input";
import {
  isAlphaNumeric,
  isNotEmpty,
  isPhoneNumberWithoutCountryCode,
  hasMaxLength,
  isNumeric,
} from "../../../../utils/validations";
import EditInputItem from "../../Edit/EditInputItem";
import EditAddressInputs from "../../Edit/EditAddressInputs";
import InputCheckBox from "../../../UI/Input/InputCheckBox";
import plusIcon from "/assets/common/Plus_White.svg";
import cardIcon from "/assets/subscription/Card.svg";
import Button from "../../../UI/Button/ButtonCustom";
import infoIcon from "/assets/common/MoreInfo.svg";
import useHover from "../../../../hooks/use-hover";
import {
  ERROR_MSG__NAME,
  ERROR_MSG__TITLE,
  ERROR_MSG__PHONE_NUMBER,
  ERROR_MSG__COUNTRIES_REQUIRED,
  ERROR_MSG__ONLY_NUMBERS,
  ERROR_MSG__ACH_NUMBER,
} from "../../../../consts/ErrorMessages";
import { LIST__OFFICER_TITLES } from "../../../../consts/TextConstants";

export const BusinessInfoForm = ({ formData, onChange, showError }) => {
  const { primaryEntity } = useSelector((state) => state.entity);

  const [showBusinessAddressError, setShowBusinessAddressError] =
    useState(false);

  const [title, setTitle] = useState("");
  const [showTitleError, setShowTitleError] = useState(false);

  const {
    value: officerName,
    handleChange: handleOfficerNameChange,
    handleBlur: handleOfficerNameBlur,
    handleFocus: handleOfficerNameFocus,
    isValid: isOfficerNameValid,
    showError: showOfficerNameError,
    setShowError: setShowOfficerNameError,
  } = useInput(isNotEmpty, formData.officerName);

  const {
    value: phoneNumber,
    isValid: isPhoneNumberValid,
    handleChange: handlePhoneNumberChange,
    handleBlur: handlePhoneNumberBlur,
    handleFocus: handlePhoneNumberFocus,
    showError: showPhoneNumberError,
    setShowError: setShowPhoneNumberError,
  } = useInput(isPhoneNumberWithoutCountryCode, formData.phoneNumber);

  useEffect(() => {
    if (showError) {
      setShowOfficerNameError(!isOfficerNameValid);
      setShowTitleError(!title);
      setShowPhoneNumberError(!isPhoneNumberValid);
      setShowBusinessAddressError(!formData.businessAddress?.isValid);
    }
  }, [showError]);

  const handleInputChange = (field, value) => {
    onChange(field, value);
  };

  const handleAddressChange = (address) => {
    onChange("businessAddress", address);
    if (showBusinessAddressError) {
      setShowBusinessAddressError(!address.isValid);
    }
  };

  return (
    <div className={styles.form__container}>
      <EditInputItem
        labelTitle="Requesting Officer Name"
        onChange={(e) => {
          handleOfficerNameChange(e);
          handleInputChange("officerName", e.target.value);
        }}
        onBlur={handleOfficerNameBlur}
        onFocus={handleOfficerNameFocus}
        value={officerName}
        showError={showOfficerNameError}
        errorMessage={ERROR_MSG__NAME}
      />
      <EditInputItem
        labelTitle="Title"
        type="dropdown"
        onChange={(value) => {
          setTitle(value);
          handleInputChange("title", value);
          setShowTitleError(false);
        }}
        options={LIST__OFFICER_TITLES}
        value={title}
        showError={showTitleError}
        errorMessage={ERROR_MSG__TITLE}
      />
      <EditInputItem
        labelTitle="PhoneNumber"
        onChange={(e) => {
          handlePhoneNumberChange(e);
          handleInputChange("phoneNumber", e.target.value);
        }}
        onBlur={handlePhoneNumberBlur}
        onFocus={handlePhoneNumberFocus}
        value={phoneNumber}
        showError={showPhoneNumberError}
        errorMessage={ERROR_MSG__PHONE_NUMBER}
      />

      <div></div>

      <EditAddressInputs
        onChange={handleAddressChange}
        defaultValue={
          formData.businessAddress?.addressParams ||
          primaryEntity?.registrationInfo?.businessAddressParams
        }
        // shouldUpdateToDefaultValue={true}
        showError={showBusinessAddressError}
      />
    </div>
  );
};

export const DeliveryInfoForm = ({ formData, onChange, showError }) => {
  const [showDeliveryAddressError, setShowDeliveryAddressError] =
    useState(null);

  const {
    value: appliedCountries,
    isValid: isAppliedCountriesValid,
    showError: showAppliedCountriesError,
    handleChange: handleAppliedCountriesChange,
    handleBlur: handleAppliedCountriesBlur,
    handleFocus: handleAppliedCountriesFocus,
    setShowError: setShowAppliedCountriesError,
  } = useInput(isNotEmpty, formData.appliedCountries);

  useEffect(() => {
    if (showError) {
      setShowAppliedCountriesError(!isAppliedCountriesValid);
      setShowDeliveryAddressError(!formData.deliveryAddress?.isValid);
    }
  }, [showError]);

  const handleAddressChange = (address) => {
    onChange("deliveryAddress", address);
    if (showDeliveryAddressError) {
      setShowDeliveryAddressError(!address.isValid);
    }
  };

  const handleCheckChange = (event) => {
    const isChecked = event.target.checked;

    onChange("isDeliveryAddressSameAsBusiness", isChecked);
    onChange(
      "deliveryAddress",
      isChecked
        ? formData.businessAddress
        : {
            address: "",
            isValid: false,
          }
    );
    if (isChecked) {
      setShowDeliveryAddressError(false);
    }
  };
  const [isHovered, hoverProps] = useHover();

  return (
    <div className={styles.form__container}>
      <div className={styles.question}>
        Where do you want the TRC delivered?
        <div className={styles.more_info} {...hoverProps}>
          <img src={infoIcon} alt="" />
          {isHovered && (
            <div className={styles.more_info_tooltip}>
              Preferably US address
            </div>
          )}
        </div>
      </div>

      <EditAddressInputs
        onChange={handleAddressChange}
        showTitle={false}
        showCustomItem={true}
        defaultValue={formData.deliveryAddress?.addressParams}
        shouldUpdateToDefaultValue={formData?.isDeliveryAddressSameAsBusiness}
        showError={showDeliveryAddressError}
      >
        <InputCheckBox
          title="Same as Business Address"
          onChange={handleCheckChange}
          checked={formData?.isDeliveryAddressSameAsBusiness}
          labelStyle={styles.check_box}
        />
      </EditAddressInputs>

      <EditInputItem
        labelTitle="Countries applied for"
        moreinfotext="Enter countries separating them by commas"
        onChange={(e) => {
          handleAppliedCountriesChange(e);
          onChange("appliedCountries", e.target.value);
        }}
        onBlur={handleAppliedCountriesBlur}
        onFocus={handleAppliedCountriesFocus}
        value={appliedCountries}
        inputcontainerstyle={styles.input__container_applied_countries}
        className={styles.input__applied_countries}
        showError={showAppliedCountriesError}
        errorMessage={ERROR_MSG__COUNTRIES_REQUIRED}
      />
    </div>
  );
};

export const PaymentTypeForm = ({ onSelect, sub_text }) => {
  return (
    <div className={styles.btn__container}>
      <div className={styles.subtext}>{sub_text}</div>
      <div className={styles.btn__payment_types}>
        <Button className={styles.btn__payment_type} onClick={onSelect}>
          <div className={styles.icon__container}>
            <img src={plusIcon} alt="" />
          </div>
          ACH Debit
        </Button>
        <Button className={styles.btn__payment_type}>
          <img src={cardIcon} alt="" />
          Credit or Debit Card
        </Button>
      </div>
    </div>
  );
};

export const BankDetailsForm = ({ formData, onChange, showError }) => {
  const [showBankAddressError, setShowBankAddressError] = useState(null);

  const {
    value: achNumber,
    isValid: isACHNumberValid,
    showError: showACHNumberError,
    handleChange: handleACHNumberChange,
    handleBlur: handleACHNumberBlur,
    handleFocus: handleACHNumberFocus,
    setShowError: setShowACHNumberError,
  } = useInput(
    (value) => isAlphaNumeric(value) && hasMaxLength(value, 9),
    formData.achNumber
  );

  const {
    value: accountNumber,
    isValid: isAccountNumberValid,
    showError: showAccountNumberError,
    handleChange: handleAccountNumberChange,
    handleBlur: handleAccountNumberBlur,
    handleFocus: handleAccountNumberFocus,
    setShowError: setShowAccountNumberError,
  } = useInput(
    (value) => isNumeric(value) && isNotEmpty(value),
    formData.accountNumber
  );

  const {
    value: bankName,
    isValid: isBankNameValid,
    showError: showBankNameError,
    handleChange: handleBankNameChange,
    handleBlur: handleBankNameBlur,
    handleFocus: handleBankNameFocus,
    setShowError: setShowBankNameError,
  } = useInput(isNotEmpty, formData.bankName);

  useEffect(() => {
    if (showError) {
      setShowACHNumberError(!isACHNumberValid);
      setShowAccountNumberError(!isAccountNumberValid);
      setShowBankNameError(!isBankNameValid);
      setShowBankAddressError(!formData.bankAddress?.isValid);
    }
  }, [showError]);

  const handleAddressChange = (address) => {
    onChange("bankAddress", address);
    if (showBankAddressError) {
      setShowBankAddressError(!address.isValid);
    }
  };

  return (
    <div className={styles.form__container}>
      <EditInputItem
        labelTitle="ACH Number"
        onChange={(e) => {
          handleACHNumberChange(e);
          onChange("achNumber", e.target.value);
        }}
        onBlur={handleACHNumberBlur}
        onFocus={handleACHNumberFocus}
        value={achNumber}
        showError={showACHNumberError}
        errorMessage={ERROR_MSG__ACH_NUMBER}
      />
      <EditInputItem
        labelTitle="Account Number"
        onChange={(e) => {
          handleAccountNumberChange(e);
          onChange("accountNumber", e.target.value);
        }}
        onBlur={handleAccountNumberBlur}
        onFocus={handleAccountNumberFocus}
        value={accountNumber}
        showError={showAccountNumberError}
        errorMessage={ERROR_MSG__ONLY_NUMBERS}
      />

      <div></div>
      <div className={styles.question}>Bank Details</div>

      <EditInputItem
        labelTitle="Bank Name"
        onChange={(e) => {
          handleBankNameChange(e);
          onChange("bankName", e.target.value);
        }}
        onBlur={handleBankNameBlur}
        onFocus={handleBankNameFocus}
        value={bankName}
        showError={showBankNameError}
        errorMessage={ERROR_MSG__NAME}
      />
      <EditAddressInputs
        onChange={handleAddressChange}
        showInAddressFormat={false}
        showGroupTitle={false}
        showError={showBankAddressError}
      />
      <div className={`${styles.question} ${styles.disclaimer}`}>
        <div className={styles.info_icon}>i</div>
        This Bank Account will be used for payment of Govt. Charges
      </div>
    </div>
  );
};
