


import React from 'react';
import styles from './ServiceModalFilter.module.css';
import closeIcon from '/assets/Close.svg';

const ServiceModalFilter = ({ searchQuery, setSearchQuery, onClose }) => {
    return (
        <div className={styles.modal_header}>
            <h1 className={styles.service_name}>Services</h1>
            <div className={styles.search_container}>
                <input
                    type="text"
                    className={styles.search_input}
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <button onClick={onClose} className={styles.btn__close}>
                <img src={closeIcon} alt="close" />
            </button>
        </div>
    );
};

export default ServiceModalFilter;




