import { useEffect, useState } from "react";
import EditOverlay from "../Edit/EditOverlay";
import { capTableStakes } from "../../../consts/Constants";
import styles from "./AddStakeHolderOverlay.module.css";
import AddNewShareHolder from "./AddNewShareHolder";
import AddNewSAFEs from "./AddNewSAFEs";
import AddNewStockOptionPool from "./AddNewStockOptionPool";

const AddStakeHolderOverlay = ({ ...props }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const [title, setTitle] = useState("");

  let tempTitle = "Shareholder";
  
  let subText = (
    <div className={styles.sub_text}>
      As on <span>31 Dec 2023</span>
    </div>
  );

  let content = (
    <AddNewShareHolder
      stakeType={props.stakeType}
      onChangeTitle={setTitle}
      {...props}
    />
  );

  if (props.stakeType == capTableStakes.SAFES.key) {
    tempTitle = "SAFEs";
    subText = null;
    content = <AddNewSAFEs stakeType={props.stakeType} {...props} />;
  } else if (props.stakeType == capTableStakes.STOCK_OPTION_POOLS.key) {
    tempTitle = "Stock Option Pool";
    subText = <div className={styles.sub_text}>Period: Jan - Dec</div>;
    content = <AddNewStockOptionPool stakeType={props.stakeType} {...props} />;
  }

  useEffect(() => {
    setTitle(tempTitle);
  }, []);

  return (
    <EditOverlay
      title={title}
      onClose={props.onClose}
      titleStyle={`${styles.title} ${
        props.stakeType == capTableStakes.STOCK_OPTION_POOLS.key
          ? styles.title_right
          : ""
      }`}
      sub_text={subText}
    >
      {content}
    </EditOverlay>
  );
};

export default AddStakeHolderOverlay;
