import styles from "./BookKeepingLinkOverlay.module.css";
import OverlayRegular from "../../UI/Modal/OverlayRegular";
import ButtonCustom from "../../UI/Button/ButtonCustom";
import Button from "../../UI/Button";
import {
  BTN__LOGIN,
  TEXT__BOOK_KEEPING_TAG_LINE,
  BTN__SIGNUP,
} from "../../../consts/TextConstants";
import zohoBooksIcon from "/assets/finance/ZohoBooks.svg";
import moreInfoIcon from "/assets/common/MoreInfo.svg";
import closeIcon from "/assets/Close.svg";
import MoreInfo from "../../UI/General/MoreInfo";
import { URL__REDIRECT_ZOHO } from "../../../consts/APIConstants";

const BookKeepingLinkOverlay = ({ ...props }) => {
  const handleZohoLoginClick = () => {
    window.location.href = URL__REDIRECT_ZOHO;
  };
  return (
    <OverlayRegular className={styles.overlay} blurValue="0.15rem">
      <button className={styles.btn__close} onClick={props.onClose}>
        <img src={closeIcon} alt="" />
      </button>
      <div className={styles.container_zoho_books_icon}>
        <img src={zohoBooksIcon} />
      </div>
      <div className={styles.tagline}>
        {TEXT__BOOK_KEEPING_TAG_LINE}
        <MoreInfo content="Merze will be added as a admin user automatically" position="top"/>
  
      </div>
      <div className={styles.action_items}>
        <Button className={styles.btn__action} onClick={handleZohoLoginClick}>{BTN__LOGIN}</Button>
        OR
        <Button className={styles.btn__action} buttonstyle="no-fill">
          {BTN__SIGNUP}
        </Button>
      </div>
    </OverlayRegular>
  );
};

export default BookKeepingLinkOverlay;
