import { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import useInput from "../../../../hooks/use-input";
import EditFormInputs from "../../Edit/EditFormInputs";
import EditInputItem from "../../Edit/EditInputItem";
import EditFormActionGroup from "../../Edit/EditFormActionGroup";
import {
  LBL__ENTITY_NAME,
  LBL__ENTITY_TYPE,
  LBL__INCORPORATION_DATE,
  BTN__RETRY,
  BTN__SUBMIT,
  LBL__COUNTRY,
  LBL__STATE,
  entityTypes,
  LBL__SELECT_STATE,
} from "../../../../consts/TextConstants";
import {
  ERROR_MSG__ENTITY_NAME,
  ERROR_MSG__COUNTRY,
  ERROR_MSG__STATE,
  ERROR_MSG__ENTITY_TYPE,
  ERROR_MSG__SELECT_STATE,
} from "../../../../consts/ErrorMessages";
import { isNotEmpty, isNonUSCountry } from "../../../../utils/validations";
import {
  getCountriesList,
  convertToISODate,
} from "../../../../utils/common-utils";
import {
  useCreateOtherEntityMutation,
  useLazyGetEntitiesQuery,
} from "../apis/entityApi-slice";
import MessageDialog from "../../../UI/Modal/MessageDialog";
import { PAYLD__UPDATE_ENTITY } from "../../../../consts/PayloadConstants";
import { formatRequestDataToJSON } from "../../../../utils/dataFormatter";
import { getFilteredUSStatesList } from "../../../../utils/listUSStates";

const countryList = getCountriesList();

const AddEntity = ({ onSuccess, ...props }) => {
  const dispatch = useDispatch();

  const [country, setCountry] = useState("");
  const [incorporationDate, setIncorporationDate] = useState(new Date());
  const [entityType, setEntityType] = useState("");
  const [incorporationUSState, setIncorporationUSState] = useState("");

  const [error, setError] = useState({ isError: false });
  const [showEntityTypeError, setShowEntityTypeError] = useState(false);
  const [showCountryError, setShowCountryError] = useState(false);
  const [showIncorporationUSStateError, setShowIncorporationUSStateError] =
    useState(false);
  const [getEntities] = useLazyGetEntitiesQuery();

  const [createOtherEntity, { isLoading }] = useCreateOtherEntityMutation();

  const isCountryNonUS = useMemo(() => isNonUSCountry(country), [country]);

  const {
    value: entityName,
    isValid: isEntityNameValid,
    showError: showEntityNameError,
    handleChange: handleEntityNameChange,
    handleBlur: handleEntityNameBlur,
    handleFocus: handleEntityNameFocus,
    setShowError: setShowEntityNameError,
  } = useInput(isNotEmpty);

  const {
    value: state,
    isValid: isStateValid,
    showError: showStateError,
    handleChange: handleStateChange,
    handleBlur: handleStateBlur,
    handleFocus: handleStateFocus,
    setShowError: setShowStateError,
  } = useInput(isNotEmpty);

  useEffect(() => {
    setEntityType("");
  }, [isCountryNonUS]);

  const handleIncorporationUSStateChange = (item) => {
    setIncorporationUSState(item.key);
    setShowIncorporationUSStateError(false);
  };

  const validateForm = () => {
    if (
      !isEntityNameValid ||
      !country ||
      !entityType ||
      (isCountryNonUS ? !isStateValid : !incorporationUSState)
    ) {
      setShowEntityNameError(!isEntityNameValid);
      setShowStateError(!isStateValid);
      setShowCountryError(!country);
      setShowEntityTypeError(!entityType);
      setShowIncorporationUSStateError(!incorporationUSState);

      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const requestData = formatRequestDataToJSON(
        {
          entityName,
          incorporationCountry: country,
          incorporationState: isCountryNonUS ? state : incorporationUSState,
          incorporationDate: convertToISODate(incorporationDate),
          entityType,
        },
        PAYLD__UPDATE_ENTITY
      );

      const responseData = await createOtherEntity(requestData).unwrap();
      // await getEntities();
      onSuccess();
      props.onClose();
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };
  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  let formContent = (
    <EditFormInputs>
      <EditInputItem
        name="entityName"
        labelTitle={LBL__ENTITY_NAME}
        onChange={handleEntityNameChange}
        onBlur={handleEntityNameBlur}
        onFocus={handleEntityNameFocus}
        value={entityName}
        showError={showEntityNameError}
        errorMessage={ERROR_MSG__ENTITY_NAME}
      />

      <EditInputItem
        name={`${name}-country`}
        type="autocomplete"
        labelTitle={LBL__COUNTRY}
        value={country || ""}
        // defaultValue={}
        onChange={(item) => {
          setCountry(item.value);
          setShowCountryError(false);
        }}
        suggestions={countryList}
        disabled={props.lockedCountry != null}
        showError={showCountryError}
        errorMessage={ERROR_MSG__COUNTRY}
      />

      {isCountryNonUS ? (
        <EditInputItem
          name="state"
          labelTitle={LBL__STATE}
          onChange={handleStateChange}
          onBlur={handleStateBlur}
          onFocus={handleStateFocus}
          value={state}
          showError={showStateError}
          errorMessage={ERROR_MSG__STATE}
        />
      ) : (
        <EditInputItem
          name="incorporationState"
          type="autocomplete"
          labelTitle={LBL__SELECT_STATE}
          value={incorporationUSState || ""}
          defaultValue={incorporationUSState}
          customFilter={true}
          filteredListFunc={getFilteredUSStatesList}
          onChange={handleIncorporationUSStateChange}
          // autocompleteSize={styles.autocomplete}
          showError={showIncorporationUSStateError}
          errorMessage={ERROR_MSG__SELECT_STATE}
        />
      )}

      <EditInputItem
        name="incorporationDate"
        type="datepicker"
        labelTitle={LBL__INCORPORATION_DATE}
        onChange={(value) => setIncorporationDate(value)}
        value={incorporationDate}
      />

      {country && (
        <EditInputItem
          name="entityType"
          labelTitle={LBL__ENTITY_TYPE}
          type="dropdown"
          onChange={(type) => {
            setEntityType(type);
            setShowEntityTypeError(false);
          }}
          options={isCountryNonUS ? entityTypes.NON_US : entityTypes.US}
          value={entityType}
          showListOnTop={true}
          showError={showEntityTypeError}
          errorMessage={ERROR_MSG__ENTITY_TYPE}
        />
      )}
    </EditFormInputs>
  );

  let actionContent = (
    <EditFormActionGroup
      isLoading={isLoading}
      primaryTitle={BTN__SUBMIT}
      onClick={handleSubmit}
      // disabled={!isEntityNameValid || !entityType || !isStateValid || !country}
    />
  );

  return (
    <>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle={BTN__RETRY}
          onClose={handleCloseError}
        />
      )}
      {formContent}
      {actionContent}
    </>
  );
};

export default AddEntity;
