import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ServiceTypes from './ServiceTypes';
import ServiceForm from './ServiceForm';
import styles from './ServiceList.module.css';
import { serviceActions } from '../reducers/service-slice';
import { useGetServicesQuery, useAddServicesMutation } from '../apis/serviceApi-slice';

const ServiceList = ({ searchQuery, closeModal, onAdd, setIsModalOpen }) => {


    const { services } = useSelector((state) => state.services);
    const dispatch = useDispatch();
    const [selectedService, setSelectedService] = useState(services[0]);

    const { data, isLoading, isSuccess, isError, error } = useGetServicesQuery();
    const [addServices, { isLoading: isAddServicesLoading }] = useAddServicesMutation();

    const handleServiceClick = (service) => {
        dispatch(serviceActions.setSelectedService(service));
        setSelectedService(service);
    };

    const handleAddButtonClick = async (data) => {
        onAdd(data);
        setSelectedService(null);
        // try {
        //     const result = await addServices({ serviceName: "TRC", price: 200 }).unwrap();
        // } catch (err) {
        //     console.error('Error adding service:', err);
        // }
    };

    return isLoading ? <div>Loading...</div> : (
        <div className={styles.middle_cont}>
            <ServiceTypes
                selectedService={selectedService}
                handleServiceClick={handleServiceClick}
                searchQuery={searchQuery}
                services={services}
            />
            <ServiceForm
                selectedService={selectedService}
                services={services}
                handleAddButtonClick={handleAddButtonClick}
                onClose={closeModal}
                onQuotePopupOpen={() => setIsModalOpen(false)}
            />
        </div>
    );
};

export default ServiceList;





