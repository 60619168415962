import { forwardRef } from "react";
import styles from "./IconButtonCircle.module.css";

const IconButtonCircle = forwardRef(
  ({ children, className, ...props }, ref) => {
    return (
      <button
        className={`${styles.ui__button} ${className}`}
        {...props}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

export default IconButtonCircle;
