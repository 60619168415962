import { getImageURL } from "../components/MainNavigation/SideBar/NavigationLists";



export const paymentDetails_US_Brex = {
  accountHolderName: {
    title: "Account Holder Name",
    detail: "TRANQUILITY CONSULTING LLP",
    suggestion: "",
    copyToClipboard: true,
  },
  accountNumber: {
    title: "Account Number",
    detail: "015906000048",
    suggestion: "(USD only)",
    copyToClipboard: true,
  },
  swiftCode: {
    title: "SWIFT CODE",
    detail: "ICICINBBCTS",
    suggestion: "(For wire transfers)",
    copyToClipboard: true,
  },
  ifscCode: {
    title: "IFSC Code",
    detail: "ICIC0000159",
    suggestion: "(For other modes of transfers)",
    copyToClipboard: true,
  },
  bank: {
    title: "Bank",
    detail: "ICICI BANK",
    suggestion: "",
    copyToClipboard: true,
  },
  purposeCode: {
    title: "Purpose Code",
    detail: "P1015",
    suggestion: "(Tax compliance)",
    copyToClipboard: true,
  },
};
export const paymentDetails_US_Others = {
  accountHolderName: {
    title: "Account Holder Name",
    detail: "TRANQUILITY CONSULTING LLP",
    copyToClipboard: true,
  },
  accountNumber: {
    title: "Account Number",
    detail: "8331882536",
    suggestion: "(USD only)",
    copyToClipboard: true,
  },
  achRoutingNumber: {
    title: "ACH Routing Number",
    detail: "026073150",
    suggestion: "(For wire transfers)",
    copyToClipboard: true,
  },
  accountType: {
    title: "Account Type",
    detail: "Business Checking",
    copyToClipboard: false,
  },
  bank: {
    title: "Bank",
    detail: "Community Federal Savings Bank",
    copyToClipboard: true,
  },
  beneficiaryAddress: {
    title: "Beneficiary Address",
    detail: "810 Seventh Avenue, New York, NY 10019, US",
    copyToClipboard: false,
  },
  fedwireRoutingNumber: {
    title: "Fedwire Routing No.",
    suggestion: "(for other modes of transfer)",
    copyToClipboard: true

  },

};
export const paymentDetails_India = {
  accountHolderName: {
    title: "Account Holder Name",
    detail: "TRANQUILITY CONSULTING LLP",
    suggestion: "",
    copyToClipboard: true,
  },
  accountNumber: {
    title: "Account Number",
    detail: "020405009429",
    copyToClipboard: true,
  },
  ifscCode: {
    title: "IFSC Code",
    detail: "ICIC0000204",
    copyToClipboard: true,
  },
  accountType: {
    title: "Account Type",
    detail: "CURRENT",
    copyToClipboard: false,
  },
  bank: {
    title: "Bank",
    detail: " ICICI BANK",
    copyToClipboard: true,
  },
};




export const paymentLinks_US_Main = [
  {
    id: "Mercury",
    path: "https://app.mercury.com/login",
    image: "/assets/subscription/links/Mercury.svg",
  },
  {
    id: "Brex",
    path: "https://accounts.brex.com/login",
    image: "/assets/subscription/links/Brex.svg",
  },

];
export const paymentLinks_US_Others = [
  {
    id: "BOA",
    path: "https://secure.bankofamerica.com/login/sign-in/signOnV2Screen.go",
    image: "/assets/subscription/links/Boa.svg",
  },
  {
    id: "SVB",
    path: "https://www.svbconnect.com/auth/",
    image: "/assets/subscription/links/Svb.svg",
  },
  {
    id: "Other",
    path: "",
    image: "/assets/subscription/links/Otherbanks.svg",
  },
];
export const paymentLinks_India = [
  {
    id: "Icici",
    path: "https://cibnext.icicibank.com/corp/AuthenticationController?FORMSGROUP_ID__=AuthenticationFG&__START_TRAN_FLAG__=Y&FG_BUTTONS__=LOAD&ACTION.LOAD=Y&AuthenticationFG.LOGIN_FLAG=1&BANK_ID=ICI&ITM=nli_corp_primer_login_btn_desk&_ga=2.207133072.205773951.1715668873-1569336037.1715011838",
    image: "/assets/subscription/links/Icici.svg",
  },
  {
    id: "Hdfc",
    path: "https://mybusiness.digital.hdfcbank.com/login",
    image: "/assets/subscription/links/Hdfc.svg",
  },
  {
    id: "Axis",
    path: "https://idp.axisbank.co.in/mib/Welcome",
    image: "/assets/subscription/links/Axis.svg",
  },
];
