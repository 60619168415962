
import React from 'react';
import styles from './QuotePopup.module.css';
import OverlayRegular from '../../../UI/Modal/OverlayRegular';


const QuotePopup = ({ onClose }) => {

    return (
        <OverlayRegular>
            <div className={styles.overlay} onClick={onClose}></div>
            <div className={styles.quote_popup_page}>
                <div className={styles.small_popup_para}>
                    Thanks for your Interest,
                    Our Sales Team will get back to you with the best Quote.
                </div>

                <button onClick={() => {
                    onClose();

                }} className={styles.quote_button}>
                    CLOSE
                </button>
            </div>
        </OverlayRegular >

    );
};



export default QuotePopup;


