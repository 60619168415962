import { useDispatch, useSelector } from "react-redux";
import useInput from "../../hooks/use-input.jsx";
import styles from "./RegisterUserDetails.module.css";
import {
  BTN__CLOSE,
  BTN__CREATE,
  BTN__RETRY,
  ERR_DEFAULT,
  ERR_USER_NAME,
  LBL__CONTACT_NUMBER,
  LBL__USER_NAME,
  MSG_SUCCESS_REGISTER,
  MSG_TITLE_SUCCESS,
  PLACEHOLDER_USER_NAME,
} from "../../consts/TextConstants.jsx";
import LogoBox from "../UI/LogoBox.jsx";
import Input from "../UI/Input/Input.jsx";

import PhoneInput from "../UI/Input/PhoneInput/PhoneInput.jsx";
import { AnimatePresence } from "framer-motion";
import MessageDialog from "../UI/Modal/MessageDialog.jsx";
import { useState } from "react";
import { isNotEmpty, isPhoneNumber } from "../../utils/validations.jsx";
import Form from "../UI/Form.jsx";
import Button from "../UI/Button.jsx";
import { useNavigate } from "react-router-dom";
import { loginActions } from "../../store/login-slice.jsx";
import { useSendUserDetailsMutation } from "../../store/authApi-slice.jsx";
import { useLazyGetFlagsQuery } from "../Dashboard/apis/dashboardApi-slice.jsx";

const RegisterUserDetails = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState({ show: false, isError: false });
  const { user: email } = useSelector((state) => state.auth);
  const [phoneNumber, setPhoneNumber] = useState("+1");
  const navigate = useNavigate();
  const {
    value: name,
    isValid: isNameValid,
    showError: showNameError,
    handleChange: handleNameChange,
    handleBlur: handleNameBlur,
    handleFocus: handleNameFocus,
  } = useInput(isNotEmpty);

  const [sendUserDetails, { isFetching: isLoading }] =
    useSendUserDetailsMutation();

  const [getFlags, { isFetching: isLoadingFlags }] = useLazyGetFlagsQuery();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await sendUserDetails({
        username: name,
        phone: phoneNumber,
      }).unwrap();
      dispatch(loginActions.setShowUserDetailsForm(false));
      try {
        await getFlags();
        navigate("/home");
      } catch (error) {
        setMessage({
          show: true,
          isError: true,
          title: "Error",
          message: ERR_DEFAULT,
          redirectToLogin: true,
        });
      }
    } catch (err) {
      setMessage({
        show: true,
        isError: true,
        title: "Create Account Error",
        message: err?.data?.message || ERR_DEFAULT,
      });
    }
  };

  const handleCloseMessage = () => {
    setMessage({ show: false });

    if (!message.isError) {
      dispatch(loginActions.setShowCreatePassword(false));
      props.onSubmit();
    }
  };
  return (
    <div className={styles.form_container}>
      <AnimatePresence>
        {message.show && (
          <MessageDialog
            isError={message?.isError}
            title={message?.title}
            message={message?.message}
            buttonTitle={message?.isError ? BTN__RETRY : BTN__CLOSE}
            onClose={handleCloseMessage}
          />
        )}
      </AnimatePresence>
      <Form className={styles.form} onSubmit={handleSubmit}>
        <Input
          label={LBL__USER_NAME}
          id="input_name"
          type="text"
          onChange={handleNameChange}
          onBlur={handleNameBlur}
          onFocus={handleNameFocus}
          value={name}
          name="userName"
          showError={showNameError}
          errormessage={ERR_USER_NAME}
          placeholder={PLACEHOLDER_USER_NAME}
        />
        <PhoneInput
          value={phoneNumber}
          onChange={setPhoneNumber}
          labelTitle={LBL__CONTACT_NUMBER}
        />
        <Button
          className={styles.btn__create}
          disabled={!isNameValid || !isPhoneNumber(phoneNumber) || isLoading}
          isLoading={isLoading}
        >
          {BTN__CREATE}
        </Button>
      </Form>
    </div>
  );
};

export default RegisterUserDetails;
