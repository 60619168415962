import React, { useMemo, useState, useEffect, useRef } from "react";
import { CustomTable, TableData, TableRow } from "../../UI/Table/CustomTable";

import styles from "./AddedServicesList.module.css";
import rightArrow from "/assets/common/ArrowRight_White.svg";
import fileGray from "/assets/common/File_Gray.svg";
import emailIcon from "/assets/subscription/links/EmailIcon.svg";

import ActionItems from "./ActionItems";
import { useSelector } from "react-redux";

import Payments from "./PaymentsFolder/Payments";
import EmailCard from "../../UI/Email/EmailCard";
import ServicesDocumentOverlay from "./ServicesDocumentsOverlay";
import { TBL__SERVICES } from "../../../consts/TextConstants";

const AddedServicesList = ({ onSuccess }) => {
  const { primaryEntity } = useSelector((state) => state.entity);
  const { servicesAdded } = useSelector((state) => state.services);

  const [showPayments, setShowPayments] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [showEmail, setShowEmail] = useState(false);
  const [showMoreInfoDropdown, setShowMoreInfoDropdown] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showServicesDocumentOverlay, setShowServicesDocumentOverlay] =
    useState(false);

  const emailRefs = useRef([]);

  const getActionDescription = (paymentStatus) => {
    const status = paymentStatus?.toLowerCase();
    switch (status) {
      case "pending":
        return "Please Complete Payment";
      case "verification":
        return "Verifying payment";
      default:
        return "";
    }
  };

  const handleEmail = (service) => {
    setShowMoreInfoDropdown(false);

    setSelectedService(service);
    setShowEmail(true);
  };

  const handlePayClick = (service) => {
    setSelectedService(service);
    setShowPayments(true);
  };

  const handleMoreInfoClick = (row) => {
    setSelectedRow(row);
    setShowMoreInfoDropdown(true);
  };

  const handleOverlayClose = () => {
    setSelectedRow(null);
    setShowEmail(false);
  };

  const handleServicesDocumentOverlay = () => {
    setShowServicesDocumentOverlay(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showMoreInfoDropdown &&
        emailRefs.current[selectedRow] &&
        !emailRefs.current[selectedRow].contains(event.target)
      ) {
        setShowMoreInfoDropdown(false);
        setSelectedRow(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMoreInfoDropdown]);

  const handlePaymentClose = () => {
    setShowPayments(false);
    onSuccess();
  };

  return (
    <div className={styles.table_container}>
      <CustomTable className={styles.table}>
        <tbody>
          {servicesAdded.length > 0 ? (
            servicesAdded.map((serviceItem, row) => (
              <TableRow
                className={styles.table_height}
                key={`service-row-${row}`}
              >
                {TBL__SERVICES.map((key, col) => (
                  <TableData key={`service-row-${key}-${row}-${{ col }}`}>
                    {key === "taskStatus" && (
                      <div
                        className={`${styles.task_status} ${
                          styles[serviceItem.taskStatus]
                        }`}
                      >
                        {/* {serviceItem.taskStatus === "pending" &&
                          "This task is pending."} */}
                      </div>
                    )}
                    {key === "serviceName" && (
                      <div className={styles.contentWrapper}>
                        <span>{serviceItem.serviceName}</span>{" "}
                        {/* Render the service name */}
                        <div className={styles.entity_name}>
                          {primaryEntity?.entityName} .{" "}
                          {/* Render the entity name */}
                          <span>{serviceItem?.year} </span>.{" "}
                          {serviceItem?.assigneeName}
                        </div>
                      </div>
                    )}
                    {key === "description" && (
                      <div className={styles.contentWrapper}>
                        <div className={styles.short_desc}>
                          <span>{serviceItem.serviceShortDescription}</span>
                          {serviceItem.serviceName ===
                            "Audit & Review Report" && (
                            <span> : {serviceItem?.yearDescription}</span>
                          )}
                        </div>
                      </div>
                    )}

                    {key === "actionItems" &&
                      (serviceItem.taskStatus?.toLowerCase() === "active" ? (
                        <ActionItems
                          serviceItem={serviceItem}
                          entityId={primaryEntity?.entityId}
                          onSuccess={onSuccess}
                        />
                      ) : (
                        <div className={styles.action_description}>
                          {getActionDescription(serviceItem.paymentStatus)}
                        </div>
                      ))}

                    {key === "documents" && (
                      <div className={styles.file_content} onClick={() => {}}>
                        <img src={fileGray} alt="File" />
                      </div>
                    )}
                    {key === "paymentStatus" && (
                      <div className={styles.verification_status}>
                        {serviceItem.paymentStatus?.toLowerCase() ===
                        "pending" ? (
                          <button
                            className={styles.pay_btn}
                            onClick={() => handlePayClick(serviceItem)}
                          >
                            PAY{" "}
                            <span>
                              <img
                                src={rightArrow}
                                alt="right-arrow"
                                className={styles.rightArrow}
                              />
                            </span>
                          </button>
                        ) : (
                          <div
                            className={`${styles.payment_status} ${
                              styles[serviceItem.paymentStatus?.toLowerCase()]
                            }`}
                          >
                            {serviceItem.paymentStatus[0]?.toUpperCase()}
                          </div>
                        )}
                      </div>
                    )}
                    {key === "moreInfo" && (
                      <div
                        className={styles.dots}
                        onClick={() => handleMoreInfoClick(row)}
                        ref={(comp) => (emailRefs.current[row] = comp)}
                      >
                        {showMoreInfoDropdown && selectedRow == row && (
                          <div className={styles.email_card}>
                            <div
                              className={styles.card_content}
                              onClick={() => handleEmail(serviceItem)}
                            >
                              <img
                                src={emailIcon}
                                className={styles.email_icon}
                                alt="Email Icon"
                              />
                              <span>Email</span>
                            </div>
                          </div>
                        )}
                        ...
                      </div>
                    )}
                  </TableData>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableData colSpan="6">
                <div className={styles.noData}>No services found</div>
              </TableData>
            </TableRow>
          )}
        </tbody>
      </CustomTable>

      {showPayments && selectedService && (
        <Payments
          onClose={handlePaymentClose}
          selectedService={selectedService}
          subscriptionId={selectedService.subscriptionId}
        />
      )}
      {showEmail && (
        <EmailCard
          onClose={handleOverlayClose}
          serviceItem={selectedService}
          entityId={primaryEntity?.entityId}
          subscriptionId={selectedService.subscriptionId}
        />
      )}
      {showServicesDocumentOverlay && (
        <ServicesDocumentOverlay onClose={handleServicesDocumentOverlay} />
      )}
    </div>
  );
};

export default AddedServicesList;
