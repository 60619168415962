import { useState } from "react";
import styles from "./ListItem.module.css";
import { motion } from "framer-motion";

const ListItem = ({
  icon,
  active,
  selectedIcon,
  labelTitle,
  iconActive,
  disabled = false,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={styles.container} onClick={props.onClick}>
      {active && (
        <motion.div
          className={styles.active}
          layoutId="nav-active"
        ></motion.div>
      )}

      {isHovered && !active && !disabled && (
        <motion.div className={styles.hover} layoutId="nav-hover"></motion.div>
      )}

      <div
        className={`${styles.list_item} ${disabled ? styles.list_item_disabled : ""}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={styles.icon}>
          <img src={active ? iconActive : icon} alt="icon" />
        </div>
        <div
          className={`${styles.label} ${active ? styles.label_active : ""} ${
            disabled ? styles.label_disabled : ""
          }`}
        >
          {labelTitle}
          {disabled && <div className={styles.disabled}>COMING SOON..</div>}
        </div>
      </div>
    </div>
  );
};

export default ListItem;
