/*---------------------------Entity Error Messages-----------------------------------*/

export const ERROR_MSG__ENTITY_NAME = "Entity name is required";

export const ERROR_MSG__ENTITY_TYPE = "Select Entity Type";

export const ERROR_MSG__ENTITY_NOT_FOUND = "Entity not found";

export const ERROR_MSG__INCORPORATION_CERTIFICATE =
  "Incorporation certificate is required";

export const ERROR_MSG__PARENT = "Choose Parent";

export const ERROR_MSG__ADD_PARENT = "Add Parent Entity first";

export const ERROR_MSG__EIN_LETTER = "EIN letter is required";

export const ERROR_MSG__CURRENT_TAX_YEAR = "Select Current Tax Year";

export const ERROR_MSG__CURRENT_TAX_FORM = "Select Current Tax Form";

export const ERROR_MSG__REGISTERED_AGENT_NAME =
  "Registered Agent Name is required";

export const ERROR_MSG__NAME = "Name is required";

export const ERROR_MSG__TITLE = "Select Title";

export const ERROR_MSG__NEXUS_TYPE = "Select at least one Nexus Type";

export const ERROR_MSG__SELECT_YES_NO = "Select yes or no";

export const ERROR_MSG__SELECT_STATE = "Select State";

export const ERROR_MSG__FILE_REQUIRED = "File is required";

export const ERROR_MSG__ENTITY_DETAILS = "Failed to fetch entity details. Please try again..";

export const ERROR_MSG__BOARD_DETAILS = "Failed to fetch board details. Please try again..";

/*---------------------------Cap Table Error Messages-----------------------------------*/

export const ERROR_MSG__CAPTABLE = "Failed to fetch cap table details. Please try again..";

export const ERROR_MSG__NO_OF_SHARES = "Should be less than unissued shares";

export const ERROR_MSG__TYPE = "Select Type";

/*---------------------------Finance Error Messages-----------------------------------*/

export const ERROR_MSG__REVENUE_STATEMENT = "Failed to fetch revenue statement. Please try again..";

/*---------------------------Services Error Messages-----------------------------------*/

export const ERROR_MSG__SERVICES = "Failed to fetch services. Please try again..";

export const ERROR_MSG__TITLE_REQUIRED = "Title is required";

export const ERROR_MSG__PHONE_NUMBER = "Enter valid Phone number";

export const ERROR_MSG__COUNTRIES_REQUIRED = "Enter at least one country";

export const ERROR_MSG__ACH_NUMBER = "Allowed only alphabets and numbers containing 9 digits";
/*---------------------------Common Error Messages-----------------------------------*/

export const ERROR_MSG__ONLY_NUMBERS = "Should contain only numbers";

export const ERROR_MSG__NUMERICAL_VALUES = "Only numerical values allowed";

export const ERROR_MSG__STREET = "Street is required";

export const ERROR_MSG__CITY = "City is required";

export const ERROR_MSG__ZIP_CODE = "Zip code is required";

export const ERROR_MSG__STATE = "State is required";

export const ERROR_MSG__COUNTRY = "Choose Country from list";

export const ERROR_MSG__TRY_AGAIN = "Something went wrong. Please try again..";
