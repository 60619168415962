import { useDispatch } from "react-redux";
import EditFormInputs from "../../Edit/EditFormInputs";
import EditInputItem from "../../Edit/EditInputItem";
import EditFormActionGroup from "../../Edit/EditFormActionGroup";
import {
  LBL__REGISTERED_AGENT_NAME,
  LBL__AGENT_ADDRESS,
  BTN__SUBMIT,
} from "../../../../consts/TextConstants";
import useInput from "../../../../hooks/use-input";
import { isNotEmpty } from "../../../../utils/validations";
import { useState } from "react";
import EditAddressInputs from "../../Edit/EditAddressInputs";
import { ERROR_MSG__REGISTERED_AGENT_NAME } from "../../../../consts/ErrorMessages";

const EditRegisteredAgentInfo = ({ agentInfo, ...props }) => {
  const dispatch = useDispatch();

  const [agentAddress, setAgentAddress] = useState({
    address: agentInfo?.agentAddress?.address || "",
    isValid: false,
    addressParams: agentInfo?.agentAddress?.addressParams || "",
  });

  const [showAgentAddressError, setShowAgentAddressError] = useState(false);

  const {
    value: agentName,
    isValid: isAgentNameValid,
    showError: showAgentNameError,
    handleChange: handleAgentNameChange,
    handleBlur: handleAgentNameBlur,
    handleFocus: handleAgentNameFocus,
    setShowError: setShowAgentNameError,
  } = useInput(isNotEmpty, agentInfo?.agentName || "");

  const validateForm = () => {
    if (!isAgentNameValid || !agentAddress.isValid) {
      setShowAgentNameError(!isAgentNameValid);
      setShowAgentAddressError(!agentAddress.isValid);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    const data = {
      agentName,
      agentAddressStreet: agentAddress.addressParams.street,
      agentAddressCity: agentAddress.addressParams.city,
      agentAddressState: agentAddress.addressParams.state,
      agentAddressZip: agentAddress.addressParams.zipCode,
      agentAddressCountry: agentAddress.addressParams.country,
      isRegisterAgentEditable: false,
    };
    props.onSubmitData(data, [], "agent-entity");
  };

  return (
    <>
      <EditFormInputs>
        <EditInputItem
          name="agentName"
          labelTitle={LBL__REGISTERED_AGENT_NAME}
          onChange={handleAgentNameChange}
          onBlur={handleAgentNameBlur}
          onFocus={handleAgentNameFocus}
          value={agentName}
          showError={showAgentNameError}
          errorMessage={ERROR_MSG__REGISTERED_AGENT_NAME}
        />

        <div />

        <EditAddressInputs
          onChange={(value) => {
            setAgentAddress(value);
            if (value.isValid) setShowAgentAddressError(false);
          }}
          defaultValue={agentAddress?.addressParams}
          groupTitle={LBL__AGENT_ADDRESS}
          lockedCountry={props.lockedCountryAddress}
          lockedState={props.lockedStateAddress}
          showError={showAgentAddressError}
        />
      </EditFormInputs>

      <EditFormActionGroup
        primaryTitle={BTN__SUBMIT}
        onClick={handleSubmit}
        isLoading={props.isLoading}
        // disabled={!isAgentNameValid || !agentAddress.isValid}
      />
    </>
  );
};

export default EditRegisteredAgentInfo;
