import { FlagEmoji } from "react-international-phone";
import { TBL__CAP_TABLE_SHARES } from "../../../consts/TextConstants";
import { convertNumbersToShorts } from "../../../utils/common-utils";
import {
  CustomTable,
  TableData,
  TableFooter,
  TableHeader,
  TableRow,
} from "../../UI/Table/CustomTable";
import styles from "./ShareHolderList.module.css";
import fileIcon from "/assets/common/File_Gray.svg";
import deleteIcon from "/assets/common/Delete.svg";

const ShareHolderList = ({ shareHolders, issuedShares, ...props }) => {
  const footerData = [
    "Total",
    "",
    "",
    "",
    "",
    convertNumbersToShorts(issuedShares),
    "100 %",
  ];

  return (
    <CustomTable className={styles.table}>
      <thead>
        <TableRow>
          {Object.entries(TBL__CAP_TABLE_SHARES).map(([key, value], index) => (
            <TableHeader
              key={`table-header-column-${index}`}
              className={`${
                key == "certificate" || key == "citizenship"
                  ? styles.column_fixed_width
                  : ""
              }`}
            >
              {value}
            </TableHeader>
          ))}
          <TableHeader
            key={`table-header-column-delete`}
            className={styles.column_delete}
          ></TableHeader>
        </TableRow>
      </thead>
      <tbody>
        {shareHolders.map((shareHolder, row) => (
          <TableRow key={`table-data-row-${row}`}>
            {Object.keys(TBL__CAP_TABLE_SHARES).map((key, column) => (
              <TableData key={`table-data-row-${row}-column-${column}`}>
                {key == "certificate" && (
                  <div className={styles.img_data}>
                    <img
                      src={fileIcon}
                      alt=""
                      onClick={() => props.onFileClick(row)}
                      className={styles.img_file}
                    />
                  </div>
                )}
                {key == "citizenship" && (
                  <div className={styles.img_data_flag}>
                    <FlagEmoji
                      iso2={shareHolder[key]}
                      className={styles.img_flag}
                    />
                  </div>
                )}
                {key != "citizenship" && key != "certificate" && (
                  <>{`${key == "parValue" ? "$ " : ""}${shareHolder[key]}${
                    key == "ownership" ? " %" : ""
                  }`}</>
                )}
              </TableData>
            ))}
            <TableData>
              <button onClick={() => props.onDelete([row])} className={styles.btn__delete}>
                <img src={deleteIcon} alt="" />
              </button>
            </TableData>
          </TableRow>
        ))}
      </tbody>
      {shareHolders.length > 0 && (
        <TableFooter>
          <TableRow key={`table-footer-row-0`}>
            {footerData.map((item, index) => (
              <TableData
                key={`table-footer-row-0-column-${index}`}
                className={styles.column_footer}
              >
                {item}
              </TableData>
            ))}
          </TableRow>
        </TableFooter>
      )}
    </CustomTable>
  );
};

export default ShareHolderList;
