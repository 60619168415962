
import React, { useState } from "react";
import PayFromOptions from "./PayFromOptions";
import PaymentDetails from "./PaymentDetails";
import PriceDetails from "./PriceDetails";
import styles from "./PayFromOptions.module.css";
import { useSelector } from "react-redux";

const Payments = ({ onClose, selectedService, subscriptionId }) => {

    const [showPayOptions, setShowPayOptions] = useState(true);
    const [showPaymentDetails, setShowPaymentDetails] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedAccountType, setSelectedAccountType] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [businessName, setBusinessName] = useState("");
    const [selectedBankId, setSelectedBankId] = useState(null);


    // Handle country selection
    const handleOpenPaymentDetails = (country) => {
        setSelectedCountry(country);
        setShowPayOptions(false);
        setShowPaymentDetails(true);
    };

    // Back to payment options
    const handleBackToPayOptions = () => {
        setShowPaymentDetails(false);
        setShowPayOptions(true);
        setSelectedCountry(null);
        setSelectedAccountType(null);
    };

    // Handle file change
    const handleFileChange = (file) => {
        setSelectedFile(file);
    };

    // Handle business name change
    const handleBusinessNameChange = (name) => {
        setBusinessName(name);
    };

    // Handle account type change
    const handleAccountTypeChange = (type) => {
        setSelectedAccountType(type);
    };



    return (
        <div className={styles.payments_container}>
            {showPayOptions && (
                <PayFromOptions
                    onSelectOption={handleOpenPaymentDetails}
                    onClose={onClose}
                    selectedService={selectedService}
                />
            )}
            {showPaymentDetails && (
                <PaymentDetails
                    option={selectedCountry}
                    onBack={handleBackToPayOptions}
                    onClose={onClose}
                    selectedFile={selectedFile}
                    onChangeBank={setSelectedBankId}


                >
                    <PriceDetails
                        option={selectedCountry}
                        accountType={selectedAccountType}
                        selectedFile={selectedFile}
                        onFileChange={handleFileChange}
                        onFileUpload={null}
                        onClose={onClose}
                        businessName={businessName}
                        handleBusinessNameChange={handleBusinessNameChange}
                        selectedService={selectedService}
                        subscriptionId={subscriptionId}
                        selectedBankId={selectedBankId}

                    />
                </PaymentDetails>
            )}
        </div>
    );
};

export default Payments;













