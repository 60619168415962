import React, { useState } from "react";
import styles from "./AttachRecipt.module.css";
import attachReciptIcon from "/assets/subscription/links/Attachrecipt.svg";
import {
  useUploadReceiptMutation,
  useLazyGetAllUserServicesQuery,
} from "../apis/serviceApi-slice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import SuccessPage from "./PaymentSuccessPage";
import { DEFAULT_PAGE_SIZE } from "../../../../consts/AppConstants";

const AttachRecipt = ({
  onServiceAdded,
  selectedBankId,
  onClose,
  subscriptionId,
  onFileChange,
}) => {
  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState(null); // State for selected file
  const [uploadReceipt] = useUploadReceiptMutation(); // Hook for file upload mutation
  const [getAllServices] = useLazyGetAllUserServicesQuery();

  // State to manage SuccessPage visibility
  const [showSuccessPage, setShowSuccessPage] = useState(false);

  // Get the entity_id from the Redux store
  const { primaryEntity } = useSelector((state) => state.entity);
  const entity_id = primaryEntity?.entityId;

  // Handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
    onFileChange(true);
  };

  // Handle submit
  const handleSubmit = async (event) => {
    // onClose();
    event.preventDefault();
    // if (selectedFile && entity_id && selectedBankId && subscriptionId) {
    if (selectedFile && entity_id) {
      const formData = new FormData();
      formData.set("receipt", selectedFile);

      try {
        // Send POST request via the mutation
        const response = await uploadReceipt({
          params: {
            entity_id,
            payment_bank_id: selectedBankId,
            subscription_id: subscriptionId,
          },
          data: formData,
        }).unwrap();

        if (onServiceAdded) onServiceAdded();

        // await getAllServices({
        //   entity_id: entity_id,
        //   page: 1,
        //   perPage: DEFAULT_PAGE_SIZE,
        // }).unwrap();

        // Show the SuccessPage after successful upload
        setShowSuccessPage(true);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      console.error("File or Entity ID missing");
    }
  };

  const handleCloseSuccessPage = () => {
    setShowSuccessPage(false); // Close the SuccessPage
    onClose();
  };

  return (
    <div className={styles.file_submit}>
      <div className={styles.attach_file_cont}>
        <div
          className={styles.attach_file}
          onClick={() => document.getElementById("fileInput").click()}
        >
          <img
            src={attachReciptIcon}
            alt="Upload"
            className={styles.uploadImage}
          />
          <div
            className={
              selectedFile ? styles.file_name_selected : styles.file_name
            }
          >
            {selectedFile ? selectedFile.name : "ATTACH PAYMENT CONFIRMATION"}
          </div>

          <input
            id="fileInput"
            type="file"
            onChange={handleFileChange} // File change handler
            style={{ display: "none" }}
          />
        </div>
      </div>

      <button
        className={styles.submit_button}
        onClick={handleSubmit}
        disabled={!selectedFile || !entity_id} // Disable if no file or entityId is available
      >
        SUBMIT
      </button>
      {showSuccessPage && <SuccessPage onClose={handleCloseSuccessPage} />}
    </div>
  );
};

export default AttachRecipt;
