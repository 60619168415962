import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FlagEmoji } from "react-international-phone";
import styles from "./EntityList.module.css";
import {
  CustomTable,
  TableRow,
  TableHeader,
  TableData,
} from "../../UI/Table/CustomTable";
import { TBL__ALL_ENITITES } from "../../../consts/TextConstants";
import arrowComplete from "/assets/subscription/links/DoubleRightArrow.svg";
import arrowIcon from "/assets/common/ArrowRight_Grey.svg";
import ButtonCustom from "../../UI/Button/ButtonCustom";
import EntityEditPage from "./EntityEdit/EntityEditPage";
import { entityActions } from "./reducers/entity-slice";
import { entityEditForms } from "../../../consts/Constants";
import {
  countriesLookup,
  convertISODateToStr,
} from "../../../utils/common-utils";

const EntityList = ({ entities, onSuccess }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openEditOverlay, setOpenEditOverlay] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const handleRowClick = (entity) => {
    if (entity.isBasicComplete) {
      navigate(`${entity.entityId}`);
    }
  };

  const handleCompleteDetails = (event, entity) => {
    if (!entity.isBasicComplete) {
      event.stopPropagation();
      setOpenEditOverlay(true);
      setSelectedEntity(entity);
      dispatch(entityActions.setEditForm(entityEditForms.REGISTRATION));
    }
  };

  return (
    <>
      <CustomTable className={styles.table}>
        <thead>
          <TableRow key={`table-header-row-${0}`}>
            <TableHeader key={`table-header-status`}></TableHeader>
            {Object.values(TBL__ALL_ENITITES).map((item, col) => (
              <TableHeader key={`table-header-${col}`}>{item}</TableHeader>
            ))}
            <TableHeader key={`table-header-complete-details`}></TableHeader>

            <TableHeader key={`table-header-arrow`}></TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {entities.map((entity, row) => (
            <TableRow
              key={`table-row-${row}`}
              onClick={() => handleRowClick(entity)}
            >
              <TableData key={`table-data-status-${row}`}>
                <div
                  className={`${styles.status} ${
                    entity.isFullComplete ? styles.completed : styles.pending
                  }`}
                ></div>
              </TableData>
              {Object.keys(TBL__ALL_ENITITES).map((key, col) => (
                <TableData key={`table-data-${col}-${row}`}>
                  {key === "country" && (
                    <div className={styles.img_data_flag}>
                      <FlagEmoji
                        iso2={countriesLookup(entity[key])?.key}
                        className={styles.img_flag}
                      />
                    </div>
                  )}
                  {key === "incorporationDate"
                    ? convertISODateToStr(entity[key])
                    : key !== "country" && entity[key]}
                </TableData>
              ))}
              <TableData key={`table-data-complete-details-${row}`}>
                {!entity.isFullComplete && (
                  <ButtonCustom
                    className={styles.btn__complete}
                    onClick={(event) => handleCompleteDetails(event, entity)}
                  >
                    Complete Details
                    <img src={arrowComplete} alt="" />
                  </ButtonCustom>
                )}
              </TableData>

              <TableData key={`table-data-arrow-${row}`}>
                <div className={styles.arrow}>
                  {entity.isBasicComplete && <img src={arrowIcon} alt="" />}
                </div>
              </TableData>
            </TableRow>
          ))}
        </tbody>
      </CustomTable>
      {openEditOverlay && (
        <EntityEditPage
          className={styles.edit_overlay}
          onClose={() => setOpenEditOverlay(false)}
          entityId={selectedEntity.entityId}
          selectedCountry={selectedEntity.country}
          entityType={selectedEntity.entityType}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default EntityList;
