import React from "react";
import styles from "./ActionRenderer.module.css";
import { serviceActionTypes } from "./ServiceManagement/ServiceConstants";

const ActionRenderer = ({
  action: mainAction,
  onUpload,
  onDownload,
  onButtonClick,
  onConfirmation
}) => {
  const getActionComponent = (action, index = null) => {
    switch (action.type) {
      case serviceActionTypes.FORM:
        return (
          <div key={index || action.type}>
            <button
              onClick={onButtonClick}
              className={`${styles.action_form} ${
                styles[action.actionItemStyle]
              }`}
            >
              <span className={""}>{action.label}</span>
              <img src={action.icon} alt="Form Icon" className={styles.icon} />
            </button>
          </div>
        );
`   `
      case serviceActionTypes.MESSAGE:
        return (
          <div key={index || action.type}>
            <p
              className={`${styles.action_message} ${
                styles[action.actionItemStyle]
              }`}
            >
              {action.label}
            </p>
            {action.additionalInfo && (
              <p className={styles.additional_info}>{action.additionalInfo}</p>
            )}
          </div>
        );

      case serviceActionTypes.DOWNLOAD:
        return (
          <div
            className={`${styles.action_download} ${
              styles[action.actionItemStyle]
            }`}
            onClick={onDownload}
            key={index || action.type}
          >
            <img
              src={action.icon}
              alt="Download"
              className={styles.downloadIcon}
            />
            <span>{action.label}</span>
          </div>
        );

      case serviceActionTypes.UPLOAD:
        return (
          <div className={styles.uploadContainer} key={index || action.type}>
            <button
              onClick={onUpload}
              className={`${styles.action_upload} ${
                styles[action.actionItemStyle]
              }`}
            >
              <img
                src={action.icon}
                alt="Upload"
                className={styles.uploadIcon}
              />
              <span>{action.label}</span>
            </button>
          </div>
        );

      case serviceActionTypes.CONFIRMATION:
        return (
          <div className={styles.conformation_cont}>
            <p className={""}>{action.label}</p>
            <button
              className={`${styles.action_confirmation} ${
                styles[action.actionItemStyle]
              }`}
              onClick={onConfirmation}
            >
              <img src={action.icon} alt="" />
              {action.confiramtionLabel}
            </button>
          </div>
        );

      case serviceActionTypes.MULTIPLE:
        return (
          <div
            className={styles.multipleActionsContainer}
            key={index || action.type}
          >
            {action.actions.map((subAction, idx) => {
              return getActionComponent(subAction, idx);
            })}
          </div>
        );

      default:
        return null;
    }
  };

  return getActionComponent(mainAction);
};

export default ActionRenderer;
