import styles from "./MessageDialog.module.css";
import { motion } from "framer-motion";
import BackDrop from "./Backdrop";
import ButtonCustom from "../Button/ButtonCustom";
import errorIcon from "/assets/Error.svg";
import { BTN__CLOSE } from "../../../consts/TextConstants";

const MessageDialog = ({ title, message, isError = true, buttonTitle=BTN__CLOSE, ...props }) => {
  const overlay = {
    hidden: { opacity: 0, y: "40rem" },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <BackDrop
      className={styles.backdrop}
      onClose={props.onClose}
      blurValue={"0.25rem"}
    >
      <motion.div
        key="overlay-error"
        variants={overlay}
        initial="hidden"
        animate="visible"
        exit="hidden"
        transition={{
          ease: "easeInOut",
          duration: 0.6,
          type: "spring",
          stiffness: 100,
        }}
        className={styles.main}
      >
        {isError && <img className={styles.errorIcon} src={errorIcon} alt="" />}
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.message}>{message}</p>
        <ButtonCustom className={styles.btn__close} onClick={props.onClose}>
          {buttonTitle}
        </ButtonCustom>
      </motion.div>
    </BackDrop>
  );
};

export default MessageDialog;
