import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingIndicator from "../../UI/LoadingIndicator/LoadingIndicator.jsx";
import { useDispatch, useSelector } from "react-redux";

import MessageDialog from "../../UI/Modal/MessageDialog.jsx";
import { BTN__RETRY, BTN__CLOSE } from "../../../consts/TextConstants.jsx";
import {
  useLazyGetAccountBalanceQuery,
  useLazyZohoCallBackQuery,
} from "./apis/financeApi-slice.jsx";
import { REDIRECT_URL__ZOHO } from "../../../consts/APIConstants.jsx";
import { useGetPrimaryEntityQuery } from "../Entities/apis/entityApi-slice.jsx";
import { financeActions } from "./reducers/finance-slice.jsx";
import LoadingComponent from "../../UI/LoadingIndicator/LoadingComponent.jsx";

let isFirstTime = true;
const ZohoBooksPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const accountServer = searchParams.get("accounts-server");
  const { primaryEntity } = useSelector((state) => state.entity);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [message, setMessage] = useState({ show: false, isError: false });
  const { isSuccess, error, isLoading } = useGetPrimaryEntityQuery({ email: user });
  const [zohoCallBack] = useLazyZohoCallBackQuery();
  const [getAccountBalance] = useLazyGetAccountBalanceQuery();

  const handleZohoCallback = async () => {
    try {
      await zohoCallBack({
        code,
        accountServer,
        entity_id: primaryEntity?.entityId,
        redirect_uri: REDIRECT_URL__ZOHO,
      }).unwrap();

      await getAccountBalance({
        entity_id: primaryEntity?.entityId,
        redirect_uri: REDIRECT_URL__ZOHO,
      }).unwrap();

      dispatch(financeActions.setAccountingPermission(true));
    } catch (err) {
      let errorMessage = "Oops! Something went wrong.";
      setMessage({
        show: true,
        isError: true,
        title: "Error",
        message: errorMessage,
      });
      // navigate("/");
    } finally {
      navigate("/home/finance");
    }
  };
  useEffect(() => {
    if (!isLoading && isSuccess) {
      isFirstTime = false;
      handleZohoCallback();
    }
  }, [isLoading, isSuccess]);

  const handleCloseMessage = () => {
    const isError = message.isError;
    setMessage({ show: false });
    if (isError) {
      navigate("/");
    }
  };

  return (
    <div>
      <LoadingComponent />
      {message.show && (
        <MessageDialog
          isError={message?.isError}
          title={message?.title}
          message={message?.message}
          buttonTitle={message?.isError ? BTN__RETRY : BTN__CLOSE}
          onClose={handleCloseMessage}
        />
      )}
    </div>
  );
};

export default ZohoBooksPage;
