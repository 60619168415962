import styles from "./ButtonPlain.module.css";

const ButtonPlain = ({ children, className, ...props }) => {
  const classes = `${styles.ui__button} ${className}`;

  return (
    <button className={classes} {...props}>
      {children}
    </button>
  );
};

export default ButtonPlain;
