import { useState } from "react";
import styles from "./MoreInfo.module.css";
import moreInfoIcon from "/assets/common/MoreInfo.svg";

const MoreInfo = ({ content, position = "right", showMoreInfoIcon = true }) => {
  const [showMoreInfoBox, setShowMoreInfoBox] = useState(false);
  return (
    <div
      className={styles.container}
      onMouseEnter={() => setShowMoreInfoBox(true)}
      onMouseLeave={() => setShowMoreInfoBox(false)}
    >
      {showMoreInfoIcon && <img src={moreInfoIcon} alt="" />}
      {showMoreInfoBox && (
        <div className={`${styles.description} ${styles[position]}`}>
          {content}
        </div>
      )}
    </div>
  );
};

export default MoreInfo;
