export const downloadFile = async (url, queryparams, filename) => {
  try {
    fetch(url + queryparams)
      .then((response) => {
        if (response.status != 200) {
          throw new Error("Something went wrong. Please try again.");
        }
        return response;
      })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        const tempLink = document.createElement("a");
        tempLink.href = url;
        tempLink.target = "_blank";
        tempLink.setAttribute("download", filename);

        document.body.appendChild(tempLink);
        tempLink.click();

        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
      });
  } catch (err) {
    throw err;
  }
};
