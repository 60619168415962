import { useDispatch } from "react-redux";
import useInput from "../../../hooks/use-input";
import { capTableActions } from "./reducers/capTable-slice";
import EditFormActionGroup from "../Edit/EditFormActionGroup";
import EditFormInputs from "../Edit/EditFormInputs";
import EditInputItem from "../Edit/EditInputItem";
import {
  BTN__SUBMIT,
  LBL__AMOUNT,
  LBL__SAFE_HOLDER_NAME,
  LBL__ISSUE_DATE,
  LIST__MONEY_VALUATION_TYPES,
  LBL__TYPE,
  BTN__RETRY,
} from "../../../consts/TextConstants";
import { isNotEmpty, isNumeric } from "../../../utils/validations";
import { useState } from "react";
import { convertToISODate } from "../../../utils/common-utils";
import {
  useAddShareHolderMutation,
  useLazyGetShareHoldersQuery,
} from "./apis/capTableApi-slice";
import MessageDialog from "../../UI/Modal/MessageDialog";
import { PAYLD__CREATE_SHARE_HOLDER } from "../../../consts/PayloadConstants";
import { formatRequestData } from "../../../utils/dataFormatter";
import {
  ERROR_MSG__NAME,
  ERROR_MSG__NUMERICAL_VALUES,
  ERROR_MSG__TYPE,
} from "../../../consts/ErrorMessages";

const AddNewSAFEs = ({ stakeType, forYear, entityId, onSuccess, ...props }) => {
  const dispatch = useDispatch();

  const [issuedDate, setIssuedDate] = useState(new Date());
  const [valuationType, setValuationType] = useState("");

  const [error, setError] = useState({ isError: false });
  const [showValuationTypeError, setShowValuationTypeError] = useState(false);

  const {
    value: sAFEHolderName,
    isValid: isSAFEHolderNameValid,
    showError: showSAFEHolderNameError,
    handleChange: handleSAFEHolderNameChange,
    handleBlur: handleSAFEHolderNameBlur,
    handleFocus: handleSAFEHolderNameFocus,
    setShowError: setShowSAFEHolderNameError,
  } = useInput(isNotEmpty);

  const {
    value: principal,
    isValid: isPrincipalValid,
    showError: showPrincipalError,
    handleChange: handlePrincipalChange,
    handleBlur: handlePrincipalBlur,
    handleFocus: handlePrincipalFocus,
    setShowError: setShowPrincipalError,
  } = useInput((value) => isNumeric(value) && isNotEmpty(value));

  const [addShareHolder, { isLoading }] = useAddShareHolderMutation();
  // const [getShareHolders] = useLazyGetShareHoldersQuery();

  const validateForm = () => {
    if (!isSAFEHolderNameValid || !valuationType || !isPrincipalValid) {
      setShowSAFEHolderNameError(!isSAFEHolderNameValid);
      setShowPrincipalError(!isPrincipalValid);
      setShowValuationTypeError(!valuationType);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const requestData = formatRequestData(
        {
          entityId,
          shareHolder: sAFEHolderName,
          amount: principal,
          issuedDate: convertToISODate(issuedDate),
          shareType: valuationType,
          stakeType: "Safes",
          forYear: convertToISODate(forYear),
        },
        [],
        PAYLD__CREATE_SHARE_HOLDER
      );
      await addShareHolder(requestData).unwrap();
      // await getShareHolders({
      //   shareholder_as_on: convertToISODate(forYear),
      //   entity_id: entityId,
      // }).unwrap();
      onSuccess();
      props.onClose();
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };

  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };

  return (
    <>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle={BTN__RETRY}
          onClose={handleCloseError}
        />
      )}
      <EditFormInputs key="add-new-share-holder">
        <EditInputItem
          name="sAFEHolderName"
          labelTitle={LBL__SAFE_HOLDER_NAME}
          onChange={handleSAFEHolderNameChange}
          onBlur={handleSAFEHolderNameBlur}
          onFocus={handleSAFEHolderNameFocus}
          value={sAFEHolderName}
          showError={showSAFEHolderNameError}
          errorMessage={ERROR_MSG__NAME}
        />

        <EditInputItem
          name="issuedDate"
          type="datepicker"
          labelTitle={LBL__ISSUE_DATE}
          onChange={(value) => setIssuedDate(value)}
          value={issuedDate}
        />

        <EditInputItem
          name="valuationType"
          type="dropdown"
          labelTitle={LBL__TYPE}
          onChange={(value) => {
            setValuationType(value);
            setShowValuationTypeError(false);
          }}
          value={valuationType}
          options={LIST__MONEY_VALUATION_TYPES}
          errorMessage={ERROR_MSG__TYPE}
          showError={showValuationTypeError}
        />

        <EditInputItem
          name="amount"
          labelTitle={LBL__AMOUNT}
          onChange={handlePrincipalChange}
          onBlur={handlePrincipalBlur}
          onFocus={handlePrincipalFocus}
          value={principal}
          showCurrency={true}
          showError={showPrincipalError}
          errorMessage={ERROR_MSG__NUMERICAL_VALUES}
        />
      </EditFormInputs>
      <EditFormActionGroup
        primaryTitle={BTN__SUBMIT}
        onClick={handleSubmit}
        // disabled={!isSAFEHolderNameValid || !valuationType || !isPrincipalValid}
        isLoading={isLoading}
      />
    </>
  );
};

export default AddNewSAFEs;
