import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./EntityDetailsPage.module.css";
import PrimaryEntityDetails from "./PrimaryEntityDetails";
import PrimaryBoardDetails from "./PrimaryBoardDetails";
import TabListItem from "../../../UI/Tab/TabListItem";
import TabContent from "../../../UI/Tab/TabContent";
import { entityDetailsTabs, tabStyles } from "../../../../consts/Constants";
import CapTablePage from "../../CapTable/CapTablePage";
import { BTN__BACK } from "../../../../consts/TextConstants";
import arrowLeft from "/assets/common/ArrowLeft_Grey.svg";
import { useGetEntityByIdQuery } from "../apis/entityApi-slice";
import { navigaionActions } from "../../../MainNavigation/reducers/navigation-slice";
import LoadingComponent from "../../../UI/LoadingIndicator/LoadingComponent";
import ErrorComponent from "../../../UI/Error/ErrorComponent";
import { ERROR_MSG__ENTITY_DETAILS } from "../../../../consts/ErrorMessages";
import { isNonUSCountry } from "../../../../utils/validations";
const EntityDetailsPage = ({ entityId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(entityDetailsTabs.ENTITY);

  const { selectedEntity } = useSelector((state) => state.entity);

  const tabsConfig = [
    { tab: entityDetailsTabs.ENTITY },
    { tab: entityDetailsTabs.BOARD },
    {
      tab: entityDetailsTabs.CAP_TABLE,
      condition: () =>
        !isNonUSCountry(selectedEntity?.incorporationCountry) &&
        selectedEntity?.entityType === "C-Corp",
    },
  ];

  const {
    isFetching: isLoading,
    isSuccess,
    isError,
  } = useGetEntityByIdQuery(
    { entity_id: entityId },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    dispatch(
      navigaionActions.setNavigationTitle(
        selectedEntity?.entityName || "Entity Details"
      )
    );
  }, [selectedEntity]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className={styles.container}>
      <button className={styles.btn__back} onClick={() => navigate(-1)}>
        <img src={arrowLeft} alt="" />
        {BTN__BACK}
      </button>
      <div className={styles.tabs__list}>
        {tabsConfig.map(
          ({ tab, condition }) =>
            (condition ? condition() : true) && (
              <TabListItem
                key={tab}
                onClick={() => handleTabChange(tab)}
                activeTab={activeTab === tab}
                tabStyle={tabStyles.UNDERLINED}
              >
                {tab}
              </TabListItem>
            )
        )}
      </div>
      {isLoading && (
        <LoadingComponent type="data" className={styles.tab_content} />
      )}
      {isError && (
        <ErrorComponent
          errorMessage={ERROR_MSG__ENTITY_DETAILS}
          className={styles.tab_content}
        />
      )}
      {isSuccess && (
        <div className={styles.tab_content}>
          <TabContent id={entityDetailsTabs.ENTITY} activeTab={activeTab}>
            <PrimaryEntityDetails />
          </TabContent>
          <TabContent id={entityDetailsTabs.BOARD} activeTab={activeTab}>
            <PrimaryBoardDetails />
          </TabContent>
          <TabContent id={entityDetailsTabs.CAP_TABLE} activeTab={activeTab}>
            <CapTablePage />
          </TabContent>
        </div>
      )}
    </div>
  );
};

export default EntityDetailsPage;
