import styles from "./CreatePasswordForm.module.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useInput from "../../../hooks/use-input.jsx";
import { useResetPasswordMutation } from "../apis/forgotPasswordApi-slice.jsx";
import { loginActions } from "../../../store/login-slice.jsx";
import Input from "../../UI/Input/Input.jsx";
import Button from "../../UI/Button.jsx";
import Form from "../../UI/Form.jsx";
import MessageDialog from "../../UI/Modal/MessageDialog.jsx";
import { AnimatePresence } from "framer-motion";
import {
  LBL__CREATE_PASSWORD,
  LBL__CONFIRM_PASSWORD,
  ERR_PASSWORD,
  ERR_CONFIRM_PASSWORD,
  BTN__CHANGE_PASSWORD,
  MSG_SUCCESS_FORGOT_PASSWORD,
  MSG_TITLE_SUCCESS,
  BTN__RETRY,
  BTN__CLOSE,
} from "../../../consts/TextConstants.jsx";
import {
  isEqualToOtherValue,
  isPassword,
} from "../../../utils/validations.jsx";

const CreatePasswordForm = ({ ...props }) => {
  const {
    value: password,
    isValid: isPasswordValid,
    showError: showPasswordError,
    handleChange: handlePasswordChange,
    handleBlur: handlePasswordBlur,
    handleFocus: handlePasswordFocus,
  } = useInput(isPassword);

  const {
    value: confirmPassword,
    isValid: isConfirmPasswordValid,
    showError: showConfirmPasswordError,
    handleChange: handleConfirmPasswordChange,
    handleBlur: handleConfirmPasswordBlur,
    handleFocus: handleConfirmPasswordFocus,
  } = useInput((value) => isEqualToOtherValue(value, password));

  const { user: email } = useSelector((state) => state.auth);
  const [message, setMessage] = useState({ show: false, isError: false });
  const dispatch = useDispatch();

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const resultData = await resetPassword({
        email,
        newpassword: password,
      }).unwrap();
      setMessage({
        show: true,
        isError: false,
        title: MSG_TITLE_SUCCESS,
        message: MSG_SUCCESS_FORGOT_PASSWORD,
      });
    } catch (err) {
      setMessage({
        show: true,
        isError: true,
        title: "Forgot Password Error",
        message: err?.data?.message || ERR_DEFAULT,
      });
    }
  };

  const handleCloseMessage = () => {
    setMessage({ show: false });

    if (!message.isError) {
      dispatch(loginActions.setShowCreatePassword(false));
      props.onSubmit();
    }
  };

  return (
    <>
      <AnimatePresence>
        {message.show && (
          <MessageDialog
            isError={message?.isError}
            title={message?.title}
            message={message?.message}
            buttonTitle={message?.isError ? BTN__RETRY : BTN__CLOSE}
            onClose={handleCloseMessage}
          />
        )}
      </AnimatePresence>

      <Form className={styles.form} onSubmit={handleSubmit}>
        <Input
          label={LBL__CREATE_PASSWORD}
          id="input_password"
          type="password"
          onChange={handlePasswordChange}
          onBlur={handlePasswordBlur}
          onFocus={handlePasswordFocus}
          value={password}
          name="password"
          showError={showPasswordError}
          errormessage={ERR_PASSWORD}
        />
        <Input
          label={LBL__CONFIRM_PASSWORD}
          id="input_confirm_password"
          type="password"
          onChange={handleConfirmPasswordChange}
          onBlur={handleConfirmPasswordBlur}
          onFocus={handleConfirmPasswordFocus}
          value={confirmPassword}
          showError={showConfirmPasswordError}
          errormessage={ERR_CONFIRM_PASSWORD}
        />
        <Button
          className={styles.btn__submit}
          disabled={!isConfirmPasswordValid || !isPasswordValid || isLoading}
          isLoading={isLoading}
        >
          {BTN__CHANGE_PASSWORD}
        </Button>
      </Form>
    </>
  );
};

export default CreatePasswordForm;
