

import React, { useState } from "react";
import {
    LBL__PAY_FROM,
    LBL_PAY_PAID,
} from "../../../../consts/TextConstants";
import styles from "./PayFromOptions.module.css";
import rupeeIcon from "/assets/subscription/Currency_Rupee.svg";
import dollarIcon from "/assets/subscription/Currency_Dollar.svg";
import OverlayRegular from "../../../UI/Modal/OverlayRegular";
import closeIcon from "/assets/Close.svg"; // Ensure you have a close icon

import PaymentStatus from "./PaymentStatus";

const PayFromOption = ({ currency, country, onSelect }) => {
    return (
        <div onClick={() => onSelect(country)} className={styles.btn__pay_option}>
            <img src={currency} className={styles.currency_img} alt="" />
            <span className={styles.country_name}>{country}</span>
        </div>
    );
};

const PayFromOptions = ({ onSelectOption, onClose, selectedService }) => {
    return (
        <OverlayRegular>
            <div className={styles.pfo_cont}>
                <button className={styles.btn__close} onClick={onClose}>
                    <img src={closeIcon} alt="Close" />
                </button>
                <PaymentStatus />

                <div className={styles.label_para}>{LBL__PAY_FROM}</div>
                <div className={styles.middle_cont}>
                    <div className={styles.pay_cont}>
                        <div className={styles.pay_para}>
                            {LBL_PAY_PAID}
                        </div>
                        <div className={styles.pay_price}>
                            ${selectedService.servicePrice}
                        </div>
                    </div>
                </div>
                <div className={styles.choose_country_cont}>
                    <div className={styles.pay_options}>
                        <PayFromOption currency={dollarIcon} country="U.S.A" onSelect={onSelectOption} />
                        <PayFromOption currency={rupeeIcon} country="India" onSelect={onSelectOption} />
                    </div>
                </div>
            </div>
        </OverlayRegular>
    );
};

export default PayFromOptions;








































