import styles from "./CapTablePage.module.css";
import editIcon from "/assets/common/EditIcon.svg";
import { useEffect, useState } from "react";
import { capTableStakes, tabStyles } from "../../../consts/Constants";
import TabListItem from "../../UI/Tab/TabListItem";
import ButtonCustom from "../../UI/Button/ButtonCustom";
import {
  BTN__ADD,
  MSG__DELETE_CONFIRMATION,
} from "../../../consts/TextConstants";
import addIcon from "/assets/common/Plus_White.svg";
import downloadIcon from "/assets/common/Download.svg";
import downloadEnabled from "/assets/common/Download_Blue.svg";
import AddStakeHolderOverlay from "./AddStakeHolderOverlay";
import { AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../UI/Modal/ConfirmationDialog";
import { capTableActions } from "./reducers/capTable-slice";
import { motion } from "framer-motion";
import YearDropdown from "../../UI/Dropdown/YearDropDown";
import {
  convertNumbersToShorts,
  getDateFromString,
  convertToISODate,
  convertISODateToStr,
} from "../../../utils/common-utils";
import { navigaionActions } from "../../MainNavigation/reducers/navigation-slice";
import {
  useDeleteShareHolderMutation,
  useGetShareHoldersQuery,
  useLazyGetShareHoldersQuery,
} from "./apis/capTableApi-slice";
import {
  BASE_URL,
  URL_GET_SHARE_CERTIFICATE,
  URL_GET_SHARE_HOLDER_EXCEL,
} from "../../../consts/APIConstants";
import { downloadFile } from "../../../utils/fileHandler";
import { getAsOnYearsList } from "../../../utils/dataFormatter";
import CapTableTabContent from "./CapTableTabContent";
import { ERROR_MSG__CAPTABLE } from "../../../consts/ErrorMessages";
import LoadingComponent from "../../UI/LoadingIndicator/LoadingComponent";
import ErrorComponent from "../../UI/Error/ErrorComponent";

const ShareHoldingTypeDetail = ({
  type,
  quantity,
  hoverQuantity = null,
  isHover,
  showEditOption = false,
}) => {
  const hoverData = hoverQuantity || quantity;
  return (
    <motion.div className={styles.share_container} layout>
      <div className={styles.share_content_wrapper}>
        <div className={styles.share_type}>{type}</div>
        <div
          className={`${styles.share_quantity} ${isHover ? styles.hover : ""}`}
        >
          {isHover ? hoverData : quantity}
          {showEditOption && isHover && (
            <div className={styles.share_edit_icon}>
              <img src={editIcon} alt="" />
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const CapTablePage = () => {
  const dispatch = useDispatch();

  const { selectedEntity } = useSelector((state) => state.entity);
  const { stakesData, authorizedShares, issuedShares, nonIssuedShares } =
    useSelector((state) => state.capTable);

  const { asOnYearsList, showCurrentDateAsDefault } = getAsOnYearsList(
    selectedEntity?.incorporationDate,
    selectedEntity?.irsInfo?.currentTaxYear || "Jan - Dec"
  );

  const [activeTab, setActiveTab] = useState(capTableStakes.SHARES.key);
  const [openAddOverlay, setOpenAddOverlay] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({ show: false });
  const [isHover, setIsHover] = useState(false);
  const [forYear, setForYear] = useState(asOnYearsList[0]);

  const { isSuccess, error, isFetching: isLoading } = useGetShareHoldersQuery({
    shareholder_as_on: new Date(forYear).toISOString(),
    entity_id: selectedEntity?.entityId,
  });
  const [deleteShareHolder] = useDeleteShareHolderMutation();
  const [
    getShareHolders,
    {
      isSuccess: isSuccessOnTrigger,
      error: errorOnTrigget,
      isFetching: isLoadingOnTrigger,
    },
  ] = useLazyGetShareHoldersQuery();

  useEffect(() => {
    dispatch(navigaionActions.setNavigationTitle(selectedEntity?.entityName));
  }, []);

  const fetchShareHoldersData = async (year) => {
    try {
      await getShareHolders({
        shareholder_as_on: new Date(year).toISOString(),
        entity_id: selectedEntity?.entityId,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSuccess = async () => {
    await fetchShareHoldersData(forYear);
  };

  const handleAsOnYearChange = async (year) => {
    setForYear(year);
    dispatch(capTableActions.resetCaptableData());
    await fetchShareHoldersData(year);
  };

  const handleAddClose = () => {
    setOpenAddOverlay(false);
  };

  const handleDeleteClick = (rowList) => {
    setDeleteConfirmation({ show: true, rows: rowList, type: activeTab });
  };

  const handleConfirmationClose = () => {
    setDeleteConfirmation({ show: false });
  };

  const handleDeleteConfirm = async () => {
    try {
      for (const row of deleteConfirmation.rows) {
        await deleteShareHolder({
          shareholder_as_on: convertToISODate(forYear),
          shareholder_type: activeTab,
          entity_id: selectedEntity?.entityId,
          shareholder_name: tableData[row].shareHolder,
        }).unwrap();
      }
      await fetchShareHoldersData(forYear);
      setDeleteConfirmation({ show: false });
    } catch (err) {
      setDeleteConfirmation({ show: false });
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };

  const tableData = stakesData[activeTab];

  const handleDownloadCertificate = (row) => {
    const share_holder_name = tableData[row].shareHolder;
    if (share_holder_name == null) {
      return;
    }
    try {
      const fileDownloadURL = BASE_URL + URL_GET_SHARE_CERTIFICATE;

      const queryparams = `?entity_id=${encodeURIComponent(
        selectedEntity?.entityId
      )}&shareholder_name=${encodeURIComponent(share_holder_name)}`;

      downloadFile(fileDownloadURL, queryparams, "Share_Certificate.pdf");
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadExcel = () => {
    try {
      const fileDownloadURL = BASE_URL + URL_GET_SHARE_HOLDER_EXCEL;

      const queryparams = `?entity_id=${encodeURIComponent(
        selectedEntity?.entityId
      )}&shareholder_type=${encodeURIComponent(
        activeTab
      )}&shareholder_as_on=${encodeURIComponent(convertToISODate(forYear))}`;

      downloadFile(
        fileDownloadURL,
        queryparams,
        `Share_Table_${activeTab}.xlsx`
      );
    } catch (err) {
      console.log(err);
    }
  };

  if(isLoading || isLoadingOnTrigger) {
    return <LoadingComponent />;
  }

  if(error || errorOnTrigget) {
    return <ErrorComponent errorMessage={ERROR_MSG__CAPTABLE} />;
  }
  return (
    <>
      {(isSuccess || isSuccessOnTrigger )&& (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.page_title}>Ownership</div>
            <div className={styles.year_container}>
              <span>As on</span>
              <YearDropdown
                placeholder=""
                disabled={showCurrentDateAsDefault}
                customOptions={asOnYearsList}
                selected={
                  showCurrentDateAsDefault
                    ? convertISODateToStr(new Date())
                    : forYear
                }
                onSelect={handleAsOnYearChange}
                dropdownSize={styles.year_dropdown}
              />
            </div>
          </div>
          <div
            className={styles.section_shareholding}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            <ShareHoldingTypeDetail
              type={"Authorized"}
              quantity={convertNumbersToShorts(authorizedShares)}
              isHover={isHover}
            />
            <ShareHoldingTypeDetail
              type={"Issued"}
              quantity={convertNumbersToShorts(issuedShares)}
              hoverQuantity={`${parseFloat(
                (issuedShares * 100) / authorizedShares
              ).toFixed(2)}%`}
              isHover={isHover}
            />
            <ShareHoldingTypeDetail
              type={"Unissued"}
              quantity={convertNumbersToShorts(nonIssuedShares)}
              hoverQuantity={`${parseFloat(
                (nonIssuedShares * 100) / authorizedShares
              ).toFixed(2)}%`}
              isHover={isHover}
            />
            <ShareHoldingTypeDetail
              type={"ParValue"}
              quantity={`$${selectedEntity?.registrationInfo?.parValue || 0}`}
              isHover={isHover}
            />
          </div>
          <div className={styles.section_share_table}>
            <div className={styles.share_tabs}>
              {Object.values(capTableStakes).map((item) => (
                <TabListItem
                  key={item.key}
                  onClick={() => setActiveTab(item.key)}
                  activeTab={item.key == activeTab}
                  tabStyle={tabStyles.BACKGROUND_BOX}
                >
                  {item.value}
                </TabListItem>
              ))}
            </div>
            {!selectedEntity?.isRegistrationEditable &&
            !selectedEntity?.isIrsEditable ? (
              <div className={styles.actionItems}>
                <ButtonCustom
                  className={`${styles.ui__btn} ${styles.btn__download}`}
                  disabled={stakesData[activeTab].length == 0}
                  onClick={handleDownloadExcel}
                >
                  <img
                    src={
                      stakesData[activeTab].length == 0
                        ? downloadIcon
                        : downloadEnabled
                    }
                    alt=""
                  />
                  Download
                </ButtonCustom>
                <ButtonCustom
                  className={styles.ui__btn}
                  onClick={() => setOpenAddOverlay(true)}
                >
                  <img src={addIcon} alt="" />
                  {BTN__ADD}
                </ButtonCustom>
              </div>
            ) : (
              <div className={styles.actionItems}>
                *Please complete IRS Info to add Shareholders
              </div>
            )}
            <div className={styles.share_tab_content}>
              <CapTableTabContent
                activeTab={activeTab}
                className={styles.table}
                onDelete={handleDeleteClick}
                tableData={stakesData[activeTab]}
                onFileClick={handleDownloadCertificate}
                issuedShares={issuedShares}
              />
            </div>
          </div>
          <AnimatePresence>
            {openAddOverlay && (
              <AddStakeHolderOverlay
                onClose={handleAddClose}
                stakeType={activeTab}
                parValue={selectedEntity?.registrationInfo?.parValue}
                forYear={forYear}
                entityId={selectedEntity?.entityId}
                issuedShares={issuedShares}
                nonIssuedShares={nonIssuedShares}
                incorporationDate={getDateFromString(
                  selectedEntity?.incorporationDate
                )}
                onSuccess={handleSuccess}
              />
            )}
          </AnimatePresence>
          {deleteConfirmation.show && (
            <ConfirmationDialog
              onClose={handleConfirmationClose}
              onConfirm={handleDeleteConfirm}
              message={MSG__DELETE_CONFIRMATION}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CapTablePage;
