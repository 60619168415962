import styles from "./AddDataButton.module.css";
import plusIcon from "/assets/common/Plus_White.svg";
import ButtonCustom from "../../UI/Button/ButtonCustom";

const AddDataButton = ({ ...props }) => {
  return (
    <div className={styles.container}>
      <ButtonCustom className={styles.btn__add} onClick={props.onClick}>
        <img src={plusIcon} />
        ADD DATA
      </ButtonCustom>
    </div>
  );
};

export default AddDataButton;
