import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./TopNavBar.module.css";
import AccountDropDown from "./AccountDropDown";
import IconButtonCircle from "../../UI/Button/IconButtonCircle";
import actionIcon from "/assets/dashboard/Account.svg";
import themeSwitchIcon from "/assets/dashboard/ThemeSwitch.svg";
import contactUsIcon from "/assets/dashboard/Contact_Us.svg";
import { useLocation } from "react-router-dom";
import { navMenuFeatureItemsList } from "../SideBar/NavigationLists";
import EmailCard from "../../UI/Email/EmailCard";

const TopNavBar = ({ ...props }) => {
  const ref = useRef();
  const location = useLocation();

  const { userDetails } = useSelector((state) => state.auth);
  const { navigationTitle } = useSelector((state) => state.navigation);
  const { primaryEntity } = useSelector((state) => state.entity);

  const [showAccountDropDown, setShowAccountDropDown] = useState(false);
  const [showEmailOverlay, setShowEmailOverlay] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (
        showAccountDropDown &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setShowAccountDropDown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showAccountDropDown]);

  const handleAccountClick = () => {
    setShowAccountDropDown(!showAccountDropDown);
  };

  const classes = `${styles.topbar}`;
  // const classes = `${styles.topbar} ${
  //   location.pathname == "/home/entities" ? styles.topbar_white_bg : ""
  // }`;

  const handleContactUsClick = () => {
    setShowEmailOverlay(true);
  };

  return (
    <div className={classes}>
      
      <div className={styles.page_title}>{navigationTitle}</div>

      <div className={styles.right_side}>

        {/* {location.pathname == navMenuFeatureItemsList.home.path && (
          <div className={styles.welcome_note}>Welcome back! {userDetails?.userName}</div>
        )} */}

        <IconButtonCircle
          className={styles.action}
          onClick={handleAccountClick}
          ref={ref}
        >
          <img src={actionIcon} alt="" />
          {showAccountDropDown && <AccountDropDown onLogout={props.onLogout} />}
        </IconButtonCircle>

        <IconButtonCircle className={styles.theme}>
          <img src={themeSwitchIcon} alt="" />
        </IconButtonCircle>


        <IconButtonCircle className={styles.theme} onClick={handleContactUsClick}>
          <img src={contactUsIcon} alt="" />
        </IconButtonCircle>


      </div>

      {showEmailOverlay && <EmailCard onClose={() => setShowEmailOverlay(false)} entityId={primaryEntity?.entityId}/>}

    </div>
  );
};

export default TopNavBar;
