import { useEffect } from "react";
import styles from "./LoadingComponent.module.css";
import { getImageURL } from "../../MainNavigation/SideBar/NavigationLists";

const LoadingComponent = ({ type = "default", className }) => {
  const loadingImage = {
    default: getImageURL("/assets/loading/Loading_Default.gif"),
    data: getImageURL("/assets/loading/Loading_Data.gif"),
    list: getImageURL("/assets/loading/Loading_List.gif"),
  };

  useEffect(() => {
    // Preload the GIF
    const img = new Image();
    img.src = loadingImage[type];
  }, [type]);

  return (
    <div className={`${styles.loading_component} ${className}`}>
      <div
        className={`${styles.loading_component_container} ${styles[type]}`}
      ></div>
    </div>
  );
};

export default LoadingComponent;
