

//ServicesDocumentOverlay.jsx component below 

import React from 'react';
import styles from './ServicesDocumentsOverlay.module.css';

import closeIcon from "/assets/Close.svg";
import fileGray from "/assets/common/File_Gray.svg";
import downloadArrow from '/assets/common/DownloadArrow.svg';
import OverlayRegular from '../../UI/Modal/OverlayRegular';

const ServicesDocumentOverlay = ({ onClose }) => {

    const handleOverlayClose = () => {
        onClose();
    }

    return (
        <OverlayRegular>
            <div className={styles.submitting_application}>
                <div className={styles.doc}>
                    Documents
                    <img src={closeIcon} alt="Close" className={styles.btn__close} onClick={handleOverlayClose} />
                </div>
                <div className={styles.file_table}>
                    <div className={styles.list_item}>
                        <div>
                            <img src={fileGray} className={styles.file_style} />
                            TRC APPLICATION
                        </div>
                        <button className={styles.download_btn}>
                            <img src={downloadArrow} className={styles.down_arrow} />
                            Download
                        </button>
                    </div>
                    <div className={styles.list_item}>
                        <div>
                            <img src={fileGray} className={styles.file_style} />
                            TRC ACKNOWLEDGEMENT
                        </div>
                    </div>
                </div>
            </div>
        </OverlayRegular>
    );
};

export default ServicesDocumentOverlay;

