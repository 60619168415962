export const APP_NAME = "Merze";

/*---------------------Login Page-------------------------*/
export const LOGIN_TITLE = "Sign in to your Dashboard";

export const LOGIN_WITH_PASSWORD = "Sign in with Password";
export const LOGIN_WITH_OTP = "Sign in with OTP";

export const BTN__SIGN_IN = "Sign in";
export const BTN__REQUEST_OTP = "Submit";
export const BTN__VERIFY_OTP = "Verify";
export const BTN__REGISTER = "Register";
export const BTN__GOOGLE_SIGNIN = "Sign in with Google";
export const BTN__RESEND = "Resend";

export const LBL__EMAIL = "Email";
export const LBL__PASSWORD = "Password";
export const LBL__OTP_PART_1 =
  "Please enter the OTP(One-Time Password) sent to";
export const LBL__OTP_PART_2 = " to complete verification.";

export const ERR_EMAIL = "Invalid email address";
export const ERR_PASSWORD = "Incorrect password";

export const PLACEHOLDER_EMAIL = "Enter your email address";
export const PLACEHOLDER_PASSWORD = "Minimum 8 characteres";

export const LINK_FORGOT_PASSWORD = "Forgot password?";
/*--------------------create password -------------------*/
export const CREATE_PASSWORD_TITLE = "Create a new Password";

export const BTN__CHANGE_PASSWORD = "Change Password";

export const LBL__CONFIRM_PASSWORD = "Confirm Password";
export const LBL__CREATE_PASSWORD = "Create Password";

export const ERR_CONFIRM_PASSWORD = "Passwords do not match";

export const MSG_SUCCESS_FORGOT_PASSWORD =
  "New password has been created successfully. Please sign in to continue.";
/*----------------register user --------------------*/

export const REGISTER_TITLE = "Create Account";
export const LBL__USER_NAME = "Name";
export const LBL__PRIVACY_POLICY = "Privacy Policy";
export const LBL__TERMS_AND_CONDITIONS = "Terms & Conditions";
export const LBL__CONTACT_NUMBER = "Contact Number";

export const PLACEHOLDER_USER_NAME = "Enter your name";
export const PLACEHOLDER_CONFIRM_PASSWORD = "Confirm your password";
export const PLACEHOLDER_CONTACT_NUMBER = "Enter your phone number";

export const BTN__CREATE = "Create";
export const BTN__GO_BACK = "Go Back";

export const ERR_USER_NAME = "Name is empty";
export const ERR_CONTACT_NUMBER = "Invalid contact number";

export const MSG_TITLE_SUCCESS = "Success!!";
export const MSG_SUCCESS_REGISTER =
  "Congratulations!! you are successfully registered. Please login to continue";

/*---------------------onboarding user ---------------------*/
export const ONBOARDING_TITLE = "Let's get started.";
export const TITLE__ONBOARDING_FORM = "Create your Entity Profile";
export const TITLE__UPLOAD_DOCUMENTS = "Upload Documents";

export const LBL__BRAND_NAME = "Brand Name";
export const LBL__ENTITY_NAME = "Entity Name";
export const LBL__ENTITY_STRUCTURE = "Entity Structure";

export const LBL__INCORPORATION_STATE = "U.S Incorporation State";
export const LBL__INCORPORATION_DATE = "Incorporation Date";
export const LBL__INCORPORATION = "Incorporation";
export const LBL__ENTITY_TYPE = "Entity Type";
export const LBL__INCORPORATION_CERTIFICATE = "Incorporation Certificate";
export const LBL__EIN_SS4 = "EIN/SS4";
export const LBL__CAP_TABLE = "Cap table/Partnership Agreement";
export const LBL__BY_LAWS = "By Laws";

export const LBL__ENTITY_ID = "Entity ID";
export const LBL__AUTHORIZED_SHARES = "Authorized Shares";
export const LBL__COMMON_SHARES = "Common";
export const LBL__PREFFERED_SHARES = "Preferred";
export const LBL__STANDALONE_RADIO =
  "Does founder(s) own any other Entity inside or outside US?";
export const LBL__ENTITY_IN_OTHER_STATE =
  "Do you have Business in any other State?";
export const LBL__YES = "Yes";
export const LBL__NO = "No";
export const LBL__PARENT = "Parent";

export const PLACEHOLDER_ATTACH_FILE = "Attach File";

export const BTN__NEXT = "Next";
export const BTN__DASHBOARD = "Go to Dashboard";
export const BTN__SKIP = "Skip Documents";
export const BTN__SUBMIT = "Submit";

export const LIST__ENTITY_STRUCTURES = ["Parent", "Subsidiary", "Standalone"];

export const entityTypes = {
  NON_US: [
    "Trust / Non-profit",
    "Partnership",
    "Sole Proprietership",
    "Corporate",
  ],
  US: ["C-Corp", "LLC", "Partnership", "Sole Proprietership"],
};

export const LIST_ONBORDING_STEPS = [
  "Provide the Brand & Enity name as per Official Reports",
  "Provide incorporation details of the Entity.",
  "Upload Documents to access the Dashboard",
];

export const INSTRUCTIONS__BOOK_KEEPING_QUICK_BOOKS = ["", "", "", ""];
/*-------------------- button texts -----------------------*/

export const BTN__CONTINUE = "Continue";
export const BTN__CLOSE = "Close";
export const BTN__RETRY = "Retry";
export const BTN__SAVE = "Save";

/* ----------------- input labels --------------------------*/

//Input error messages
export const ERR_DEFAULT = "Unexpected error, please try again.";

export const ERR_COMPANY_NAME = "Please enter company name";
export const ERR_COMPANY_TYPE = "Please select company type";
export const ERR_INCORPORATION_DATE = "Please enter incorporation date";
export const ERR_INCORPORATION_STATE = "Please enter incorporation state";

/* ----------------- Dashboard --------------------------- */

export const LBL__ACCOUNT_SETTINGS = "Account Settings";
export const LBL__LOGOUT = "Sign Out";

/* ----------------- Entity Details --------------------- */
export const LBL__BUSINESS_ADDRESS = "Business Address";
export const LBL__EIN_NUMBER = "EIN Number";
export const LBL__EIN_LETTER = "EIN Letter";
export const LBL__CURRENT_TAX_YEAR = "Current Tax Year";
export const LBL__CURRENT_TAX_FORM = "Current Tax Form";
export const LBL__AGENT_NAME = "Agent Name";
export const LBL__ADDRESS = "Address";
export const LBL__ADD_FOREIGN_STATE = "Add Foreign State";
export const LBL__ADD_ENTITY_INFO = "Add Entity Information";
export const LBL__ENTITY_INFO = "Entity Information";
export const LBL__IRS_INFO = "IRS Information";
export const LBL__AGENT_INFO = "Registered Agent";
export const LBL__REGISTERED_AGENT_NAME = "Registered Agent Name";
export const LBL__REGISTERED_AGENT_ADDRESS = "Registered Agent Address";
export const LBL__AGENT_ADDRESS = "Agent Address";
export const LBL__STATE = "State";
export const LBL__SUBSIDIZATION_DATE = "Subsidization Date";
export const LBL__TAX_ID_NUMBER = "Taxation Id Number";
export const LBL__NAME = "Name";
export const LBL__TITLE = "Title";
export const LBL__STREET_ADDRESS = "Street";
export const LBL__ZIP_CODE = "Zip Code";
export const LBL__CITY = "City";
export const LBL__COUNTRY = "Country";
export const LBL__ALSO_OFFICER = "Also Officer";
export const LBL__ADD_DIRECTOR = "Add Director";
export const LBL__ADD_OFFICER = "Add Officer";
export const LBL__REGISTERED_STATE = "Registered State";
export const LBL__PAR_VALUE = "Par Value";
export const LBL__PARENT_SHARES = "No. of Shares Owned by Parent";
export const LBL__PARENT_SHARES_PERCENTAGE = "% Owned by Parent";
export const LBL__SUBSIDIARY_SHARES_PERCENTAGE = "% Owned in Sub";
export const LBL__STATE_ID = "State ID";
export const LBL__SUB_SHAREHOLDER = "Add Shareholder";
export const LBL__SUB_SHAREHOLDER_ADDRESS = "Shareholder Address";
export const LBL__REGISTRATION_CERTIFICATE = "Registration Certificate";
export const LBL__REGISTRATION_DATE = "Registration Date";
export const LBL__NEXUS_TYPE = "Nexus Type";
export const LBL__OVERLAY_NEXUS_TYPE = "Do you have?";
export const LBL__SELECT_STATE = "Select State";
export const LBL__IS_SUBSIDIARY = "This is a Subsidiary";

export const MSG__DELETE_CONFIRMATION = "Are you sure, you want to delete?";

export const TITLE__REGISTRATION = "REGISTRATION";
export const TITLE__IRS = "IRS";
export const TITLE__REGISTERED_AGENT = "REGISTERED AGENT";
export const TITLE__FORIGN_QUALIFICATION = "BUSINESS IN OTHER STATES";
export const TITLE__FORIGN_STATE = "Foreign State";
export const TITLE_OWNERSHIP = "OWNERSHIP";
export const TITLE__ENTITY_LIST = "List of Entities";
export const TITLE__ENTITY_INFORMATION = "Entity Information";
export const TITLE__ADD_ENTITY = "Add Entity";

export const BTN__BACK = "Back";
export const BTN__DONE = "Done";
export const BTN__ADD = "ADD";
export const BTN__ADD_ENTITY = "Add Entity";

export const LIST__TAX_YEARS = ["Jan - Dec", "Apr - Mar"];
export const LIST__TAX_CLASSIFICATIONS = {
  "C-Corp": ["1120"],
  LLC: ["1065", "1120", "1120S"],
  Partnership: ["1065", "1120", "1120S"],
  "Sole Proprietership": ["1040"],
};
export const LIST__TAX_FORMS = ["1065", "1120"];
export const LIST__OFFICER_TITLES = [
  "CEO",
  "CFO",
  "CTO",
  "COO",
  "Secretary",
  "President",
  "Director",
  "Treasurer",
];

export const TBL__HEADERS_LIST_DIRECTORS = {
  name: "Name",
  street: "Street Address",
  zipCode: "Zip Code",
  city: "City",
  state: "State",
  country: "Country",
};

export const TBL__HEADERS_LIST_OFFICERS = {
  name: "Name",
  title: "Title",
  street: "Street Address",
  zipCode: "Zip Code",
  city: "City",
  state: "State",
  country: "Country",
};

export const TBL__ALL_ENITITES = {
  entityName: "Entity Name",
  entityType: "Entity Type",
  country: "Country",
  state: "State",
  incorporationDate: "Incorporation Date",
};

/*****************************--------CAP TABLE----------**************************************/

export const LBL__SHAREHOLDER_NAME = "Shareholder Name";
export const LBL__SAFE_HOLDER_NAME = "SAFE holder Name";
export const LBL__OPTION_HOLDER_NAME = "Option Holder Name";
export const LBL__STAKE_TYPE = "Type of Stake owned";
export const LBL__DOCUMENT = "Share Agreement Document";
export const LBL__SHARE_CLASS = "Types of Shares owned";
export const LBL__ISSUE_DATE = "Issued Date";
export const LBL__NO_OF_SHARES = "Number of Shares owned";
export const LBL__PRICE_PER_SHARE = "Par Value";
export const LBL__PRINCIPAL = "Principal";
export const LBL__OWNERSHIP = "Ownership (in %)";
export const LBL__VALUATION_CAP = "Valuation Cap";
export const LBL__PRE_MONEY_VALUATION_CAP = "Pre Money Valuation";
export const LBL__POST_MONEY_VALUATION_CAP = "Post Money Valuation";
export const LBL__MONEY_VALUATION = "Money Valuation";
// export const LBL__STOCK_POOL_TYPE = "Type";
export const LBL__GRANT_AMOUNT = "Grant Amount";
export const LBL__GRANT_DATE = "Grant Date";
export const LBL__EXERCISE_DATE = "Exercise Date";
export const LBL__TYPE = "Type";
export const LBL__AMOUNT = "Amount";
export const LBL__NO_OF_UNITS = "No. of Units";
export const LBL__STRIKE_PRICE = "Strike Price (Optional)";

export const LIST__STOCK_POOL_OPTIONS = ["Options", "RSUs", "RSAs"];
export const LIST__MONEY_VALUATION_TYPES = [
  "Pre-Money Valuation",
  "Post-Money Valuation",
];

export const TBL__CAP_TABLE_SHARES = {
  certificate: "Certificate (Unsigned Draft)",
  shareHolder: "Shareholder",
  citizenship: "Citizenship",
  shareClass: "Share Class",
  issuedDate: "Issued Date",
  noOfShares: "No. of Shares",
  ownership: "Ownership %",
  parValue: "Par Value",
};
export const TBL__SAFES = {
  shareHolder: "SAFE Holder",
  issuedDate: "Issued Date",
  type: "Type",
  amount: "Amount",
};
export const TBL__STOCK_OPTION_POOLS = {
  shareHolder: "Option Holder",
  type: "Type",
  grantDate: "Grant Date",
  noOfUnits: "No. of Units",
  strikePrice: "Strike Price",
};

export const TBL__OWNERSHIP = {
  shareHolder: "Shareholder",
  issuedDate: "Issued Date",
  noOfShares: "No. of Shares",
  ownership: "Ownership %",
};

/* -------------------------------------- Subscription -----------------------------------------*/
export const SUB_PACKAGE_FEATURE_1 =
  "Effortless Tax Management with Automated Compliance";
export const SUB_PACKAGE_FEATURE_2 = "Get Notified for Upcoming Compliances";
export const SUB_PACKAGE_FEATURE_3 = "File Extensions for FREE";
export const SUB_PACKAGE_DISCLAIMER =
  "Package formed based on Entity Information provided.";

export const LBL__PAY_FROM = "Where are you paying from?";
export const LBL_PAY_PAID = "Amount to be paid";
export const LBL_PAY_PRICE = "$2250";
export const LBL__BUSINESS_NAME = "Business Name";
export const LBL__GST = "Enter GSTIN";
export const LBL_ACCOUNT_TYPE = "What type of account are you paying from?";

export const CURRENCY__INDIAN_RUPEE = "\u20B9";
export const CURRENCY__US_DOLLARS = "\u0024";

export const BTN__GET_QUOTE = "Get a Quote";
export const BTN__ACCEPT_AND_PAY = "ACCEPT & PAY";
export const BTN__GET_IN_TOUCH = "GET IN TOUCH";
export const BTN__ALREADY_COMPLETED = "Already Completed?";

/* -------------------------------------- Compliance -----------------------------------------*/

export const TITLE_COMPLIANCE = "Compliance";
export const TITLE_COMPLIANCE_PACKAGE = "Compliance Package";

export const LBL__YEAR = "Year";

export const BTN__GO_TO_COMPLIANCE = "Go to Compliance List";
export const BTN__ENTITY_DATA = "US Entity Data";
export const BTN__SUBSIDIARY_DATA = "Subsidiary Data";

export const MSG__QUOTATION_REQUEST_SENT =
  "Thanks for your Interest,\n our Sales Team will get back to you with the best Quote.";
export const MSG__COMPLIANCE_UPLOAD_DOCUMENTS =
  "Upload Necessary Documents to Start your Compliance";
export const MSG__TRACK_COMPLIANCE =
  "Track each Compliance and never miss a Deadline";
export const MSG__COMPLIANCE_LIST_FORMATION =
  "Compliance List Auto-generated based on Information Provided";
export const NOTE__PENDING_DATA_VERIFICATION =
  "\u002aUpload Required Information to Start your Compliance";
export const NOTE__COMPLETED_DATA_VERIFICATION =
  "\u002aCompliance List formed based on Data Provided";

export const TBL__COMPLIANCE_LIST = {
  filing: "Filing",
  form: "Form",
  period: "Period",
  dueDate: "Due Date",
  status: "Status",
};

/* ------------------------------------------Finance--------------------------------------------*/

export const TEXT__BOOK_KEEPING_TAG_LINE = "Connect your Book keeping software";
export const BTN__SIGNUP = "SIGN UP";
export const BTN__LOGIN = "LOGIN";

export const LIST__ACCOUNTING_SCOPE = ["Monthly Recurring", "Annual catch-up"];
export const LIST__ACCOUNTING_METHOD = ["Cash", "Accrual"];

export const TBL__BANK_ACCOUNTS = {
  accountNumber: "Account Number",
  bankName: "Bank Name",
  accountName: "Type",
  balance: "Balance",
};

export const TBL__FINANCIAL_STATEMENTS = {
  financialYear: "Financial Year",
  period: "Period",
  statements: "Statements",
  uploadedBy: "Uploaded by",
};

/* -------------------------------------- Services -----------------------------------------*/

// export const TBL__SERVICES = {
//   serviceName: "Services",
//   year: "Year",
//   description: "Description",

// };

// TextConstants.js
export const TBL__SERVICES = [
  "taskStatus",
  "serviceName",
  "description",
  "actionItems",
  // "documents",
  "paymentStatus",
  "moreInfo",
];

export const PRICE_DETAILS = {
  India: "₹187073",
  "U.S.A": "$2250",
};

export const MSG__TRC_RECEIVED_CONFIRMATION =
  "Please confirm you have received the TRC.";
