import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import SideBar from "../MainNavigation/SideBar/SideBar";
import TopNavBar from "../MainNavigation/TopBar/TopNavBar";
import styles from "./HomePage.module.css";
import { useGetPrimaryEntityQuery } from "../Main/Entities/apis/entityApi-slice";
import {
  useGetUserDetailsQuery,
  useGetFlagsQuery,
} from "../Dashboard/apis/dashboardApi-slice";
import LoadingComponent from "../UI/LoadingIndicator/LoadingComponent";
import ErrorComponent from "../UI/Error/ErrorComponent";
import { ERROR_MSG__TRY_AGAIN } from "../../consts/ErrorMessages";

const HomePage = ({ children }) => {
  const location = useLocation();

  const { user } = useSelector((state) => state.auth);
  const { isUserOnboarded, isBasicInfoComplete } = useSelector(
    (state) => state.dashboard
  );

  const {
    isFetching: isLoadingFlags,
    isError: isErrorFlags,
    isSuccess: isSuccessFlags,
  } = useGetFlagsQuery();

  const { isSuccess, error, isLoading } = useGetPrimaryEntityQuery({
    email: user,
  });

  const { isLoading: isLoadingUserDetails } = useGetUserDetailsQuery();

  if (isLoadingFlags) {
    return <LoadingComponent className={styles.loading} />;
  }

  if (isSuccessFlags && !isUserOnboarded) {
    if (!isBasicInfoComplete) {
      return (
        <Navigate
          to="/"
          state={{ from: location, showUserDetailsPage: true }}
        />
      );
    }
    if (location.pathname !== "/home") {
      return <Navigate to="/home" />;
    }
  }

  if (error?.status == 404 || isSuccess) {
    return (
      <div className={styles.main}>
        <SideBar />
        <TopNavBar />
        <div className={styles.content}>{children}</div>
      </div>
    );
  }

  if ((error && error?.status !== 404) || isErrorFlags) {
    return <ErrorComponent errorMessage={ERROR_MSG__TRY_AGAIN} />;
  }

  if (isLoading) {
    return <LoadingComponent className={styles.loading} />;
  }

  return <></>;
};

export default HomePage;
