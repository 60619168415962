import { useState } from "react";
import { useDispatch } from "react-redux";
import EditFormInputs from "../../Edit/EditFormInputs";
import EditInputItem from "../../Edit/EditInputItem";
import EditFormActionGroup from "../../Edit/EditFormActionGroup";
import useInput from "../../../../hooks/use-input";
import { isNumeric, isNotEmpty } from "../../../../utils/validations";
import {
  BTN__SUBMIT,
  LBL__CURRENT_TAX_FORM,
  LBL__CURRENT_TAX_YEAR,
  LBL__EIN_LETTER,
  LBL__EIN_NUMBER,
  LIST__TAX_CLASSIFICATIONS,
  LIST__TAX_YEARS,
} from "../../../../consts/TextConstants";
import {
  ERROR_MSG__EIN_LETTER,
  ERROR_MSG__CURRENT_TAX_FORM,
  ERROR_MSG__CURRENT_TAX_YEAR,
  ERROR_MSG__ONLY_NUMBERS,
} from "../../../../consts/ErrorMessages";

const EditIRSInformation = ({ irsInfo, showEINLetter = true, entityType, ...props }) => {
  const dispatch = useDispatch();
  const [einLetter, setEINLetter] = useState(irsInfo?.einLetter || "");
  const [currentTaxYear, setCurrentTaxYear] = useState(
    irsInfo?.currentTaxYear || ""
  );
  const [currentTaxForm, setCurrentTaxForm] = useState(
    irsInfo?.currentTaxForm || ""
  );

  const [showEINLetterError, setShowEINLetterError] = useState(false);
  const [showCurrentTaxYearError, setShowCurrentTaxYearError] = useState(false);
  const [showCurrentTaxFormError, setShowCurrentTaxFormError] = useState(false);

  const {
    value: einNumber,
    isValid: isEINNumberValid,
    showError: showEINNumberError,
    handleChange: handleEINNumberChange,
    handleBlur: handleEINNumberBlur,
    handleFocus: handleEINNumberFocus,
    setShowError: setShowEINNumberError,
  } = useInput(
    (value) => isNumeric(value) && isNotEmpty(value),
    irsInfo?.eINNumber || ""
  );

  const handleEINLetterChange = (selectedFile) => {
    setEINLetter(selectedFile);
    setShowEINLetterError(!selectedFile);
  };

  const validateForm = () => {
    if (
      !isEINNumberValid ||
      !currentTaxYear ||
      !currentTaxForm ||
      (showEINLetter && !einLetter)
    ) {
      setShowEINLetterError(showEINLetter && !einLetter);
      setShowCurrentTaxYearError(!currentTaxYear);
      setShowEINNumberError(!isEINNumberValid);
      setShowCurrentTaxFormError(!currentTaxForm);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    const data = {
      einNumber,
      currentTaxYear,
      currentTaxForm,
      isIrsEditable: false,
    };
    let files = [];
    if (showEINLetter) {
      files = ["ein_letter_document", einLetter];
    }
    props.onSaveData(data, files, "irs-entity");
  };

  return (
    <>
      <EditFormInputs>
        <EditInputItem
          name="einNumber"
          labelTitle={LBL__EIN_NUMBER}
          onChange={handleEINNumberChange}
          onBlur={handleEINNumberBlur}
          onFocus={handleEINNumberFocus}
          value={einNumber}
          showError={showEINNumberError}
          errorMessage={ERROR_MSG__ONLY_NUMBERS}
        />
        {showEINLetter && (
          <EditInputItem
            name="einLetter"
            labelTitle={LBL__EIN_LETTER}
            type="file"
            onChange={handleEINLetterChange}
            value={einLetter}
            showError={showEINLetterError}
            errorMessage={ERROR_MSG__EIN_LETTER}
          />
        )}

        <EditInputItem
          name="currentTaxYear"
          type="dropdown"
          labelTitle={LBL__CURRENT_TAX_YEAR}
          onChange={(value) => {
            setCurrentTaxYear(value);
            setShowCurrentTaxYearError(false);
          }}
          value={currentTaxYear}
          options={LIST__TAX_YEARS}
          showError={showCurrentTaxYearError}
          errorMessage={ERROR_MSG__CURRENT_TAX_YEAR}
        />

        <EditInputItem
          name="currentTaxForm"
          type="dropdown"
          labelTitle={LBL__CURRENT_TAX_FORM}
          onChange={(value) => {
            setCurrentTaxForm(value);
            setShowCurrentTaxFormError(false);
          }}
          value={currentTaxForm}
          options={LIST__TAX_CLASSIFICATIONS[entityType]}
          showError={showCurrentTaxFormError}
          errorMessage={ERROR_MSG__CURRENT_TAX_FORM}
          showListOnTop={true}
        />
      </EditFormInputs>
      <EditFormActionGroup
        primaryTitle={BTN__SUBMIT}
        onClick={handleSubmit}
        isLoading={props.isLoading}

        // disabled={
        //   !isEINNumberValid ||
        //   !currentTaxYear ||
        //   !currentTaxForm ||
        //   (showEINLetter && !einLetter)
        // }
      />
    </>
  );
};

export default EditIRSInformation;
