import { useState, useEffect } from "react";
import styles from "./AddNewServiceOverlay.module.css";
import OverlayRegular from "../../../UI/Modal/OverlayRegular";
import ServiceList from "./ServiceList";
import ServicesAdded from "./ServicesAdded";
import ServiceModalFilter from "./ServiceModalFilter";
import Payments from "../PaymentsFolder/Payments";

const AddNewServiceOverlay = ({ onClose, setIsModalOpen, onSuccess }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
  const [servicesAdded, setServicesAdded] = useState({});
  const [showPayments, setShowPayments] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);

  // Handles the 'Add' button click and stores the added service data
  const handleAddClick = (data) => {
    setServicesAdded(data);
    setIsAddButtonClicked(true);
  };

  // When subscriptionId is set, send it to parent (ServicesPage)
  const handleSubscriptionSuccess = (id) => {
    setIsAddButtonClicked(false);
    setShowPayments(true);
    setSubscriptionId(id);
  };

  const handlePaymentClose = () => {
    onClose();
    onSuccess();
  };

  return (
    <OverlayRegular>
      <div className={styles.service_modal}>
        <ServiceModalFilter
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onClose={onClose}
        />
        <ServiceList
          searchQuery={searchQuery}
          closeModal={onClose}
          setServicesAdded={setServicesAdded}
          onAdd={handleAddClick}
          setIsModalOpen={setIsModalOpen}
        />
        {isAddButtonClicked && (
          <ServicesAdded
            selectedService={servicesAdded}
            onClose={onClose}
            onSuccess={handleSubscriptionSuccess} // Pass the callback to handle subscription success
          />
        )}
        {showPayments && (
          <Payments
            onClose={handlePaymentClose}
            selectedService={servicesAdded}
            subscriptionId={subscriptionId}
          />
        )}
      </div>
    </OverlayRegular>
  );
};

export default AddNewServiceOverlay;
